import React, { useEffect, useState } from "react";

import { ResumeIcon, Tick } from "../../../assets/icons/interface/interface";
import SearchableMultiSelectInput from "../../../components/SearchableMultiSelectInput/SearchableMultiSelectInput";
import { compareArray } from "../../../handlers/utilityFunctions";
import dropDownOptions from "../../../seed/dropDownOptions";
import { jobTypes, seniorityLevels } from "../../../seed/job-preferences-data";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import { Modal } from "./Modal";

const JobPreferencesModal = ({
	isOpen,
	onClose,
	persistJobPreferences,
	jobPreferencesBody,
	setJobPreferences,
	setPersistJobPreferences,
	setError,
	setJobPreferencesBody,
	jobPreferences,
	resumeTitle,
	onSave,
}) => {
	const resumeDataService = React.useMemo(() => new ResumeDataService(), []);
	const jobStatuses = ["Actively Looking", "Open to Opportunities", "Not Looking", "Employed but Open"];
	const locationTypes = ["Remote", "Hybrid", "On-site", "Open to Relocation"];
	const expertiseAreas = dropDownOptions.jobExpertiseOptions;
	const [submitting, setSubmitting] = React.useState(false);
	const [hasMadeChanges, setHasMadeChanges] = React.useState(false);
	const [locationType, setLocationType] = useState("");

	const updateJobPreferences = () => {
		if (!submitting) {
			setSubmitting(true);
			resumeDataService.jobPreferences(
				jobPreferencesBody,
				(data) => {
					setJobPreferences(data?.data?.jobPreferences);
					setPersistJobPreferences(data?.data?.jobPreferences);
					localStorage.setItem("jobPreferences", JSON.stringify(data?.data));
					setError([]);
					setSubmitting(false);
					onSave();
				},
				(error) => {
					const errorMessages = error.response.data.message;
					setError(errorMessages);
					setSubmitting(false);
				},
			);
		}
	};

	useEffect(() => {
		if (persistJobPreferences?.currentJobsearchStatus !== jobPreferences?.currentJobsearchStatus) {
			setHasMadeChanges(true);
		} else if (persistJobPreferences?.waysToHelpme !== jobPreferences?.waysToHelpme) {
			setHasMadeChanges(true);
		} else if (persistJobPreferences?.primaryResumeReferenceId !== jobPreferences?.primaryResumeReferenceId) {
			setHasMadeChanges(true);
		} else if (compareArray(persistJobPreferences?.openForWorkTypes, jobPreferences?.openForWorkTypes)) {
			setHasMadeChanges(true);
		} else if (persistJobPreferences?.seniorityLevel !== jobPreferences?.seniorityLevel) {
			setHasMadeChanges(true);
		} else if (persistJobPreferences?.locationPreferenceType !== jobPreferences?.locationPreferenceType) {
			setHasMadeChanges(true);
		} else if (compareArray(persistJobPreferences?.locationsPreferred, jobPreferences?.locationsPreferred)) {
			setHasMadeChanges(true);
		} else if (compareArray(persistJobPreferences?.areasOfExpertise, jobPreferences?.areasOfExpertise)) {
			setHasMadeChanges(true);
		} else {
			setHasMadeChanges(false);
		}
	}, [persistJobPreferences, jobPreferences]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Job Preferences"
			size="large"
			primaryAction={
				hasMadeChanges
					? {
							label: submitting ? "Loading..." : "Save Preferences",
							onClick: updateJobPreferences,
						}
					: null
			}
			secondaryAction={{
				label: "Cancel",
				onClick: onClose,
			}}
		>
			<div className="space-y-8 max-h-[calc(100vh-200px)] overflow-y-auto pr-2">
				{/* Current Status */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-900">Current Job Status</h3>
					<div className="grid grid-cols-2 gap-3">
						{jobStatuses.map((status) => (
							<button
								key={status}
								onClick={() => {
									setJobPreferencesBody({
										...jobPreferencesBody,
										jobPreferences: {
											...jobPreferencesBody?.jobPreferences,
											currentJobsearchStatus: status,
										},
									});
									setJobPreferences({
										...jobPreferences,
										currentJobsearchStatus: status,
									});
								}}
								className={`ignore-focus-behavior p-3 rounded-md border flex items-center justify-between text-[14px] font-inter
                  ${jobPreferences?.currentJobsearchStatus === status ? "border-primary-600 bg-primary-50 text-primary-700" : "hover:bg-gray-50 text-gray-700"}`}
							>
								{status}
								{jobPreferences?.currentJobsearchStatus === status && <Tick className="w-5 h-5 text-primary-600" />}
							</button>
						))}
					</div>
				</div>

				{/* Resume Selection */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-900">Select Primary Resume</h3>
					<button className="w-full p-3 border rounded-lg flex items-center gap-3 hover:bg-gray-50 text-[14px] font-inter text-gray-700">
						<ResumeIcon className="w-5 h-5 text-gray-500" />
						<span>{resumeTitle}</span>
					</button>
				</div>

				{/* Job Types */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-900">Job Types</h3>
					<div className="flex flex-wrap gap-2">
						{jobTypes.map((type) => (
							<button
								key={type}
								onClick={() => {
									setJobPreferencesBody({
										...jobPreferencesBody,
										jobPreferences: {
											...jobPreferencesBody?.jobPreferences,
											openForWorkTypes: !jobPreferencesBody?.jobPreferences?.openForWorkTypes
												? [type]
												: jobPreferences?.openForWorkTypes?.includes(type)
													? jobPreferencesBody?.jobPreferences?.openForWorkTypes.filter((jobType) => jobType !== type)
													: [...jobPreferencesBody.jobPreferences.openForWorkTypes, type],
										},
									});
									setJobPreferences({
										...jobPreferences,
										openForWorkTypes: !jobPreferencesBody?.jobPreferences?.openForWorkTypes
											? [type]
											: jobPreferences?.openForWorkTypes?.includes(type)
												? jobPreferencesBody?.jobPreferences?.openForWorkTypes.filter((jobType) => jobType !== type)
												: [...jobPreferencesBody.jobPreferences.openForWorkTypes, type],
									});
								}}
								className={`ignore-focus-behavior px-4 py-2 rounded-full border text-[14px] font-inter
                  ${
										jobPreferences?.openForWorkTypes?.includes(type)
											? "border-primary-600 bg-primary-50 text-primary-700"
											: "hover:bg-gray-50 text-gray-700"
									}`}
							>
								{type}
							</button>
						))}
					</div>
				</div>

				{/* Seniority Level */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-900">Seniority Level</h3>
					<select
						value={jobPreferences?.seniorityLevel || ""}
						onChange={(e) => {
							setJobPreferencesBody({
								...jobPreferencesBody,
								jobPreferences: {
									...jobPreferencesBody?.jobPreferences,
									seniorityLevel: e.target.value,
								},
							});
							setJobPreferences({
								...jobPreferences,
								seniorityLevel: e.target.value,
							});
						}}
						className="w-full p-3 border rounded-lg bg-white text-[14px] font-inter text-gray-700"
					>
						{seniorityLevels.map((level) => (
							<option key={level.value} value={level.value}>
								{level.value}
							</option>
						))}
					</select>
				</div>

				{/* Location Types */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-900">Location Types</h3>
					<div className="grid grid-cols-2 gap-3">
						{locationTypes.map((type) => (
							<button
								key={type}
								onClick={() => setLocationType(type)}
								className={`ignore-focus-behavior p-3 rounded-md border flex items-center justify-between text-[14px] font-inter
                  ${locationType === type ? "border-primary-600 bg-primary-50 text-primary-700" : "hover:bg-gray-50 text-gray-700"}`}
							>
								{type}
								{locationType === type && <Tick className="w-5 h-5 text-primary-600" />}
							</button>
						))}
					</div>
				</div>

				{/* Areas of Expertise */}
				<SearchableMultiSelectInput
					options={expertiseAreas.map((area) => {
						return { label: area, value: area };
					})}
					onSelect={(value) => {
						setJobPreferencesBody({
							...jobPreferencesBody,
							jobPreferences: {
								...jobPreferencesBody?.jobPreferences,
								areasOfExpertise: value,
							},
						});
						setJobPreferences({
							...jobPreferences,
							areasOfExpertise: value,
						});
					}}
					value={jobPreferencesBody?.jobPreferences?.areasOfExpertise || []}
					label="What’s your superpower skill or area of expertise?"
					placeholder="Select area of expertise"
				/>
			</div>
		</Modal>
	);
};

export default JobPreferencesModal;
