import React, { useState } from "react";

import { BriefcaseIcon, MapPinIcon } from "../../../assets/icons/interface/interface";
import { capitalizeFirstLetterOfWords } from "../../../handlers/utilityFunctions";
import { Button } from "../../JobsPage/components/Button";
import { Card, CardContent } from "../../JobsPage/components/Card";

const ProfileCard = ({
	name,
	jobTitle,
	location,
	status,
	avatarUrl,
	skills,
	preferredJobTypes,
	preferredIndustries,
	onEditPreferences,
	completenessPercentage,
}) => {
	const [imageError, setImageError] = useState(false);

	return (
		<Card className="w-full">
			{/* Profile Strength Indicator */}
			<div className="p-6 border-b border-primary-200 bg-blue-50/50">
				<div className="flex items-center justify-between mb-2">
					<span className="text-sm font-medium text-primary-600">Profile Strength</span>
					<span className="text-sm font-medium text-gray-900">{completenessPercentage}%</span>
				</div>
				<div className="w-full bg-gray-200 rounded-full h-1.5">
					<div className="bg-primary-600 h-1.5 rounded-full" style={{ width: `${completenessPercentage}%` }} />
				</div>
			</div>

			<CardContent className="p-6">
				<div className="flex items-start">
					{/* Profile Image */}
					<div className="mr-6">
						<div className="rounded-full border border-gray-200 p-1">
							<img
								src={!imageError ? avatarUrl : "https://picsum.photos/200?random=1"}
								alt={`${name}'s profile picture`}
								width={120}
								height={120}
								className="rounded-full object-cover"
								onError={() => setImageError(true)}
							/>
						</div>
					</div>
					<div className="flex-grow">
						<h1 className="text-2xl font-bold font-plus-jakarta text-gray-800 mb-1">
							Welcome, {capitalizeFirstLetterOfWords(name)}
						</h1>
						{/* Job title and location with icons */}
						<div className="flex items-center text-sm text-gray-700 font-inter mb-2">
							<BriefcaseIcon className="w-4 h-4 mr-1" />
							<p>{jobTitle}</p>
							<span className="mx-2">•</span>
							<MapPinIcon className="w-4 h-4 mr-1" />
							<p>{location}</p>
						</div>

						{/* Open to Opportunities Label */}
						{status === "Open to Opportunities" && (
							<span className="inline-block bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full mb-3">
								Open to Opportunities
							</span>
						)}

						{/* Skills */}
						<div className="mt-4">
							<h2 className="text-sm font-semibold text-gray-700 font-inter mb-2">Skills</h2>
							<div className="flex flex-wrap gap-2">
								{skills.map((skill, index) => (
									<span key={index} className="bg-gray-100 text-gray-700 text-xs font-inter px-2 py-1 rounded">
										{skill}
									</span>
								))}
							</div>
						</div>

						{/* Preferred Job Types */}
						<div className="mt-4">
							<h2 className="text-sm font-semibold text-gray-700 font-inter mb-2">Preferred Job Types</h2>
							<div className="flex flex-wrap gap-2">
								{preferredJobTypes.map((jobType, index) => (
									<span key={index} className="bg-gray-100 text-gray-700 text-xs font-inter px-2 py-1 rounded">
										{jobType}
									</span>
								))}
							</div>
						</div>

						{/* Preferred Industries */}
						<div className="mt-4">
							<h2 className="text-sm font-semibold text-gray-700 font-inter mb-2">Preferred Industries</h2>
							<div className="flex flex-wrap gap-2">
								{preferredIndustries.map((industry, index) => (
									<span key={index} className="bg-gray-100 text-gray-700 text-xs font-inter px-2 py-1 rounded">
										{industry}
									</span>
								))}
							</div>
						</div>
					</div>
				</div>

				{/* Edit Profile Button */}
				<div className="mt-6">
					<Button
						variant="outline"
						className="w-full h-9 transition-colors duration-200 ease-in-out hover:bg-gray-100 hover:text-gray-900"
						onClick={onEditPreferences}
					>
						Edit Preferences
					</Button>
				</div>
			</CardContent>
		</Card>
	);
};

export default ProfileCard;
