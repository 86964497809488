import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Drawer from "../../../../components/Drawer/Drawer";
import { useLatestJobs } from "../../../../services/create-resume/queries";
import JobListingItem from "../../../JobsPage/components/JobListingItem";
import ViewJob from "./ViewJob";

function LatestJobs() {
	const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);

	const navigate = useNavigate();
	const [jobs, setJobs] = React.useState([]);

	const { data: response, isLoading } = useLatestJobs();

	React.useEffect(() => {
		if (!isLoading && response) {
			setJobs(response.data);
		}
	}, [response, isLoading]);

	return (
		<section className="bg-white py-12 px-4 md:px-8">
			<Drawer
				isOpen={showJobDetailsModal}
				onClose={() => {
					setShowJobDetailsModal(false);
				}}
			>
				<ViewJob data={selectedJob} jobs={jobs} setData={setSelectedJob} />
			</Drawer>
			<div className="max-w-6xl mx-auto">
				<h2 className="text-3xl font-bold mb-8 text-gray-900 font-plus-jakarta">Latest Job Postings</h2>
				{isLoading ? (
					<div className="animate-pulse">
						{[...new Array(3)].map((_, index) => (
							<div className="h-24 bg-gray-900 opacity-10 mb-3 rounded-md" key={index} />
						))}
					</div>
				) : (
					<div className="divide-y divide-gray-200">
						{jobs.map((job, index) => (
							<JobListingItem
								key={index}
								job={{
									...job,
									company: job.companyName,
									logo: job.company ? job.company.logo_url : "https://via.placeholder.com/150",
									salary: null,
								}}
								showCompany={true}
								onClick={() => {
									setSelectedJob(job);
									setShowJobDetailsModal(true);
								}}
								onApply={() => {
									setSelectedJob(job);
									setShowJobDetailsModal(true);
								}}
								titleClassName="text-sm font-semibold"
							/>
						))}
						<div className="flex justify-between items-center py-4 border-b border-gray-200 transition-colors duration-300">
							<div className="mt-8 mx-auto">
								<button
									className="inline-block bg-[#fff] border border-gray-200 text-gray-700 px-6 py-2 rounded hover:bg-gray-50 transition-colors font-inter"
									onClick={() => {
										navigate("/jobs", {
											state: {
												searchedAddress: "",
												searchedJobTitle: "",
												jobSearchSource: "latest",
											},
										});
									}}
								>
									View All Jobs
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</section>
	);
}
export default LatestJobs;
