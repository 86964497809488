import React, { useEffect, useRef, useState } from "react";

import { cn } from "../../handlers/cn.utils";

const SearchableMultiSelectInput = ({ options, onSelect, value = [], label, placeholder }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);

	const filteredOptions = options?.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (isOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isOpen]);

	const handleSelect = (selectedValue) => {
		if (value.includes(selectedValue)) {
			onSelect(value.filter((val) => val !== selectedValue));
		} else {
			onSelect([...value, selectedValue]);
		}
	};

	const handleRemoveChip = (selectedValue) => {
		onSelect(value.filter((val) => val !== selectedValue));
	};

	const isSelected = (optionValue) => value.includes(optionValue);

	return (
		<div ref={dropdownRef} className="relative w-full">
			<h3 className="text-[14px] font-medium font-inter text-gray-900 mb-3">{label}</h3>
			<div
				onClick={toggleDropdown}
				className="cursor-pointer flex items-center flex-wrap rounded-md border px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full border-gray-300 bg-white text-gray-800"
				style={{ minHeight: "48px", gap: "8px" }}
			>
				{value.length > 0 ? (
					options
						.filter((item) => value.includes(item.value))
						.map((item) => (
							<div
								key={item.value}
								className="flex items-center px-2 py-1 bg-blue-100 text-blue-800 rounded-full"
								style={{ gap: "4px" }}
							>
								<span>{item.label}</span>
								<button
									type="button"
									onClick={(e) => {
										e.stopPropagation();
										handleRemoveChip(item.value);
									}}
									className="text-blue-600 hover:text-blue-800 focus:outline-none"
								>
									&times;
								</button>
							</div>
						))
				) : (
					<span className="text-muted">{placeholder || "Select options"}</span>
				)}
				<i className="bi bi-chevron-down ml-auto" />
			</div>
			{isOpen && (
				<div className="absolute z-10 mt-1 rounded-md bg-white shadow-lg border w-full p-2">
					<div className="text-[18px]">Select all that apply</div>
					<input
						type="text"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						className="w-full border p-2 outline-none focus:ring-0 rounded mb-2"
						placeholder="Search"
					/>
					<ul className="max-h-60 overflow-y-auto">
						{filteredOptions.length > 0 ? (
							filteredOptions.map((option, index) => (
								<li
									key={index}
									className={cn("cursor-pointer p-2 text-left hover:bg-gray-200", {
										"text-gray-200": option.disabled,
										"text-gray-900": !option.disabled,
									})}
									onClick={() => handleSelect(option.value)}
								>
									<span className="d-flex items-center space-x-2">
										<input
											type="checkbox"
											id="checkbox"
											className="text-blue-600 focus:ring-blue-500"
											checked={isSelected(option.value)}
										/>
										<span>{option.label}</span>
									</span>
								</li>
							))
						) : (
							<li className="p-2 text-gray-500">No results found</li>
						)}
					</ul>
				</div>
			)}
		</div>
	);
};

export default SearchableMultiSelectInput;
