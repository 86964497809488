import React from "react";

import { cn } from "../../handlers/cn.utils";

function Tabs({ setCurrentView, tabs, currentView }) {
	return (
		<ul className="flex text-sm space-x-2">
			{tabs.map((tab) => (
				<li
					key={tab.key}
					onClick={() => setCurrentView(tab.key)}
					className={cn("cursor-pointer py-2 px-4 rounded", {
						"font-medium text-primary-700 bg-primary-100": tab.key === currentView,
						"text-gray-500 hover:bg-gray-100": tab.key !== currentView,
					})}
				>
					{tab.name}
				</li>
			))}
		</ul>
	);
}

export default Tabs;
