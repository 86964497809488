import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { CheckIcon, ChevronRightIcon } from "../../../assets/icons/interface/interface";
import { convertUlToArray } from "../../../handlers/utilityFunctions";
import { Button } from "../../JobsPage/components/Button";
import { Card, CardContent } from "../../JobsPage/components/Card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../JobsPage/components/Select";
import Loader from "../../StaticPages/HomePage/components/Loader";

const COMPLETED = "COMPLETED";

const ProfileStrengthCard = ({ loading, resumeData, resumeTitle, resumeList, setResumeTitle, onSelectResume }) => {
	const navigate = useNavigate();
	const [completionData, setCompletionData] = useState([]);
	const completedSections = completionData.filter((section) => section.completed).length;
	const completionPercentage = (completedSections / completionData.length) * 100;

	useEffect(() => {
		const data = [
			{
				section: "contact-information",
				title: "Personal Information",
				completed: resumeData?.progressStatus?.HEADING === COMPLETED,
				preview: `${resumeData?.contactInfo?.firstName} ${resumeData?.contactInfo?.middleName} ${resumeData?.contactInfo?.lastName}`,
			},
			{
				section: "work-history-summary",
				title: "Work History",
				completed: resumeData?.progressStatus?.EXPERIENCE === COMPLETED,
				preview: `${resumeData?.workHistory?.[0]?.jobTitle} at ${resumeData?.workHistory?.[0]?.employer}`,
			},
			{
				section: "education-summary",
				title: "Education",
				completed: resumeData?.progressStatus?.EDUCATION === COMPLETED,
				preview: `${resumeData?.education?.[0]?.fieldOfStudy}, ${resumeData?.education?.[0]?.schoolName}`,
			},
			{
				title: "Skills",
				section: "skills",
				completed: resumeData?.progressStatus?.SKILLS === COMPLETED,
				preview: convertUlToArray(resumeData?.skills)?.[0]?.value,
			},
			{
				section: "brief-summary",
				title: "Professional Summary",
				completed: resumeData?.progressStatus?.SUMMARY === COMPLETED,
				preview: (
					<span
						dangerouslySetInnerHTML={{
							__html: resumeData?.summary,
						}}
					/>
				),
			},
			[...resumeData.extras.personalLinks].length > 0
				? {
						section: "personal-links",
						title: "Personal Links",
						completed: [...resumeData.extras.personalLinks].length > 0,
						preview: `${resumeData?.extras?.personalLinks?.[0]?.type?.toUpperCase()}: ${resumeData?.extras?.personalLinks?.[0]?.value}`,
					}
				: null,
			resumeData?.extras?.accomplishments
				? {
						section: "accomplishments",
						title: "Accomplishments",
						completed: convertUlToArray(resumeData?.extras?.accomplishments)?.[0]?.value?.trim() !== "",
						preview: convertUlToArray(resumeData?.extras?.accomplishments)?.[0]?.value,
					}
				: null,
			resumeData?.extras?.affiliations
				? {
						section: "affiliations",
						title: "Affiliations",
						completed: convertUlToArray(resumeData?.extras?.affiliations)?.[0]?.value?.trim() !== "",
						preview: convertUlToArray(resumeData?.extras?.affiliations)?.[0]?.value,
					}
				: null,
			resumeData?.extras?.certifications
				? {
						section: "certifications",
						title: "Certifications",
						completed: convertUlToArray(resumeData?.extras?.certifications)?.[0]?.value?.trim() !== "",
						preview: convertUlToArray(resumeData?.extras?.certifications)?.[0]?.value,
					}
				: null,
			[...resumeData.extras.languages].length > 0
				? {
						section: "languages",
						title: "Languages",
						completed: [...resumeData.extras.languages].length > 0,
						preview: `${resumeData?.extras?.languages?.[0]?.language} - ${resumeData?.extras?.languages?.[0]?.proficiency}`,
					}
				: null,
			resumeData?.extras?.additionalInfo
				? {
						section: "additional-info",
						title: "Additional Information",
						completed: convertUlToArray(resumeData?.extras?.additionalInfo)?.[0]?.value?.trim() !== "",
						preview: convertUlToArray(resumeData?.extras?.additionalInfo)?.[0]?.value,
					}
				: null,
			[...resumeData.extras.references].length > 0
				? {
						section: "reference-summary",
						title: "References",
						completed: [...resumeData.extras.references].length > 0,
						preview: `${resumeData?.extras?.references?.[0]?.name}, ${resumeData?.extras?.references?.[0]?.designation} at ${resumeData?.extras?.references?.[0]?.workPlace}`,
					}
				: null,
			resumeData?.extras?.customExtras?.data !== ""
				? {
						section: "custom-extra/" + resumeData.extras.customExtras.name,
						title: resumeData?.extras?.customExtras?.name,
						completed: resumeData?.extras?.customExtras?.data !== "",
						preview: resumeData?.extras?.customExtras?.name,
					}
				: null,
		];

		// remove null values
		const filteredData = data.filter((item) => item !== null);

		setCompletionData(filteredData);
	}, [resumeData]);

	return (
		<>
			<div className="w-full mt-8">
				<div className="flex flex-wrap items-center justify-between mb-4">
					<h2 className="text-xl font-bold mb-4 font-plus-jakarta text-gray-800">Resume</h2>
					<div className="relative w-full sm:w-[500px]">
						<Select
							value={resumeTitle}
							onValueChange={(value) => {
								setResumeTitle(value);
								const resume = resumeList.find(
									(item) => item.journal.resumeTitle === value || item.heading.resumeTitle === value,
								);
								if (resume !== undefined) {
									onSelectResume(resume.id);
								}
							}}
						>
							<SelectTrigger className="w-full border-gray-300 bg-white text-gray-800">
								<SelectValue placeholder="Select resume" />
							</SelectTrigger>
							<SelectContent>
								{[
									...resumeList.map((resume) => {
										return {
											label: resume.journal.resumeTitle ? resume.journal.resumeTitle : resume.heading.resumeTitle,
											value: resume.journal.resumeTitle ? resume.journal.resumeTitle : resume.heading.resumeTitle,
										};
									}),
								].map((item) => (
									<SelectItem key={item.label} value={item.value}>
										{item.label}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
				</div>
				<Card className="w-full border border-gray-200">
					<CardContent className="p-6">
						{loading && <Loader />}
						{!loading && (
							<>
								<p className="text-[14px] text-gray-700 font-inter mb-4">
									Your resume is {completionPercentage.toFixed(0)}% complete. A complete resume helps you stand out to
									employers!
								</p>
								<ul className="space-y-2">
									{completionData.slice(0, 6).map((section, index) => (
										<Link key={index} to={`/resume/${section.section}`}>
											<li
												className={`flex items-center p-2 rounded-lg transition-colors duration-200 ${
													section.completed
														? "hover:bg-primary-50"
														: "opacity-80 hover:bg-gray-50 border border-dashed border-gray-300"
												}`}
											>
												<div className="w-5 h-5 mr-3 flex items-center justify-center">
													{section.completed ? (
														<CheckIcon className="w-5 h-5 text-green-600" />
													) : (
														<div className="w-5 h-5 rounded-full border-2 border-gray-400" />
													)}
												</div>
												<div className="flex-grow w-full overflow-hidden">
													<h3 className="font-semibold font-inter text-[14px] text-gray-800">{section.title}</h3>
													{section.completed && section.preview ? (
														<div className="w-5/6 overflow-hidden">
															<p className="truncate text-[14px] text-gray-700 font-inter">{section.preview}</p>
														</div>
													) : (
														<p className="text-[14px] text-gray-600 font-inter">
															{section.completed ? "No preview available" : "Section incomplete"}
														</p>
													)}
												</div>
												<ChevronRightIcon className="text-gray-500 w-4 h-4" />
											</li>
										</Link>
									))}
								</ul>
								<Button
									className="ignore-focus-behavior w-full mt-6 bg-primary-700 hover:bg-primary-800 text-white font-inter text-[14px] h-9"
									onClick={() => {
										navigate("/my-resumes");
									}}
								>
									Complete Your Resume
								</Button>
							</>
						)}
					</CardContent>
				</Card>
			</div>
		</>
	);
};

export default ProfileStrengthCard;
