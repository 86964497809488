import React from "react";
import { Modal } from "react-bootstrap";

import SearchableSelectInput from "../../../components/SearchableSelectInput/SearchableSelectInput";

function CreateModal({
	showCreateCompanyDialog,
	handleCloseCreateCompanyDialog,
	setShowCreateCompanyDialog,
	errors,
	setCompanyDetails,
	companyDetails,
	submitPhase,
	companyIndustries,
	setErrors,
	onSubmit,
}) {
	const handleOnChange = (e) => {
		const { name, value } = e.target;
		const _companyDetails = { ...companyDetails };

		_companyDetails[name] = value;
		setCompanyDetails(_companyDetails);
	};

	const handleOnSelect = (value, name) => {
		handleOnChange({ target: { value, name } });
	};

	const companySizes = ["1-10", "11-50", "51-100", "101-500", "above-500"];

	return (
		<Modal show={showCreateCompanyDialog} onHide={handleCloseCreateCompanyDialog} centered size="xl" backdrop="static">
			<Modal.Header>
				<Modal.Title>Create Company</Modal.Title>
				<button
					type="button"
					className="btn btn-secondary text-center"
					data-dismiss="modal"
					aria-label="Close"
					onClick={() => setShowCreateCompanyDialog(false)}
				>
					<i className="fa fa-close ml-2" />
				</button>
			</Modal.Header>
			<Modal.Body>
				<div>
					{errors.length > 0 && (
						<div className="alert alert-danger" role="alert">
							{errors}
						</div>
					)}
					<div className="form-group">
						<div className="mb-3">
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignContent: "flex-end",
								}}
							>
								<div style={{ width: "100%" }}>
									<label htmlFor="company_name" className="form-label">
										Company Name
									</label>

									<input
										type="text"
										className="form-control"
										onChange={handleOnChange}
										value={companyDetails.company_name}
										name="company_name"
										id="company_name"
									/>
									{errors.company_name && errors.company_name !== "" && (
										<div className="text-danger m-0">{errors.company_name}</div>
									)}
								</div>
								<div className="input-cross-tick-container">
									<i
										className={`fa text-input-cross-tick ${
											(submitPhase && !(errors.company_name && errors.company_name !== "")) ||
											(companyDetails.company_name && "fa-check text-success")
										}`}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="mb-3  col-6">
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="industry" className="form-label">
											Company Industry
										</label>

										<SearchableSelectInput
											defaultLabel="Search Company Industries"
											value={companyDetails.company_industry}
											options={companyIndustries.map((item) => {
												return { label: item, value: item };
											})}
											name="company_industry"
											onSelect={(value) => handleOnSelect(value, "company_industry")}
										/>

										{errors.company_industry && errors.company_industry !== "" && (
											<div className="text-danger m-0">{errors.company_industry}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.company_industry && errors.company_industry !== "")) ||
												(companyDetails.company_industry && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>
							<div className="mb-3 col-6">
								<label htmlFor="company_size_approx" className="form-label">
									Company Size
								</label>

								<SearchableSelectInput
									defaultLabel="Search Company Size"
									value={companyDetails.company_size_approx}
									options={companySizes.map((companySize) => {
										return { label: companySize, value: companySize };
									})}
									name="company_size_approx"
									onSelect={(value) => handleOnSelect(value, "company_size_approx")}
								/>

								{errors.company_size_approx && errors.company_size_approx !== "" && (
									<div className="text-danger m-0">{errors.company_size_approx}</div>
								)}
							</div>
						</div>

						<div className="row">
							<div className="mb-3  col-6">
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="headquarters" className="form-label">
											HeadQuarters
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.headquarters}
											name="headquarters"
											id="headquarters"
										/>
										{errors.headquarters && errors.headquarters !== "" && (
											<div className="text-danger m-0">{errors.headquarters}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.headquarters && errors.headquarters !== "")) ||
												(companyDetails.headquarters && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>
							<div className="mb-3  col-6">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="logo_url" className="form-label">
											Company Logo
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.logo_url}
											name="logo_url"
											id="logo_url"
										/>
										{errors.logo_url && errors.logo_url !== "" && (
											<div className="text-danger m-0">{errors.logo_url}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.logo_url && errors.logo_url !== "")) ||
												(companyDetails.logo_url && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-end">
					<button
						type="button"
						className="btn btn-secondary mx-1"
						data-dismiss="modal"
						onClick={() => {
							setShowCreateCompanyDialog(false);
							setErrors([]);
						}}
					>
						Cancel
					</button>

					<button
						type="button"
						className="btn btn-primary mx-1"
						disabled={
							!(
								errors.company_name === "" &&
								errors.company_industry === "" &&
								errors.company_size_approx === "" &&
								errors.headquarters === "" &&
								errors.logo_url === ""
							)
						}
						onClick={onSubmit}
					>
						ADD
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default CreateModal;
