"use client";

import * as SliderPrimitive from "@radix-ui/react-slider";
import React, { forwardRef } from "react";

import { cn } from "../../../handlers/cn.utils";

const Slider = forwardRef(({ className, ...props }, ref) => (
	<SliderPrimitive.Root
		ref={ref}
		className={cn("relative flex w-full touch-none select-none items-center", className)}
		{...props}
	>
		<SliderPrimitive.Track className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-gray-200">
			<SliderPrimitive.Range className="absolute h-full bg-primary-700" />
		</SliderPrimitive.Track>
		{Array.isArray(props.value) &&
			props.value.map((_, i) => (
				<SliderPrimitive.Thumb
					key={i}
					className="block h-4 w-4 rounded-full border border-primary-700 bg-white ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-700 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
				/>
			))}
	</SliderPrimitive.Root>
));

Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
