import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const Drawer = ({ isOpen, onClose, children }) => {
	return (
		<AnimatePresence>
			{isOpen && (
				<>
					<motion.div
						className="fixed inset-0 bg-black bg-opacity-50"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						onClick={onClose}
						style={{ zIndex: 999 }}
					/>
					<motion.div
						className="fixed top-0 right-0 h-full w-full md:w-3/4 lg:w-2/3 xl:w-1/2 bg-white shadow-lg overflow-y-auto"
						initial={{ x: "100%" }}
						animate={{ x: 0 }}
						exit={{ x: "100%" }}
						transition={{ type: "spring", stiffness: 300, damping: 30 }}
						style={{ zIndex: 1000 }}
					>
						<div className="p-4 md:p-6 h-full overflow-y-auto">
							<button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" onClick={onClose}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>
							{children}
						</div>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	);
};

export default Drawer;
