import React from "react";

import Header from "../StaticPages/Header";
import ViewJob from "../StaticPages/HomePage/components/ViewJob";

function SpecificJobDetails() {
	return (
		<div className="min-h-screen">
			<Header activeLink="jobs Details" />
			<ViewJob />
		</div>
	);
}

export default SpecificJobDetails;
