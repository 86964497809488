import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { JobsIcon, PreferencesIcon, ResumeIcon } from "../../assets/icons/interface/interface";
import ResumeContext from "../../context/ResumeContext";
import { resumeObjectToContextFormat } from "../../handlers/valueTransformation";
import useSetResumeIdCookie from "../../hooks/use-SetResumeIdCookie";
import { useLatestJobs } from "../../services/create-resume/queries";
import { ResumeDataService } from "../../services/create-resume/resume-data";
import JobListingItem from "../JobsPage/components/JobListingItem";
import Header from "../StaticPages/Header";
import FloatingNav from "./components/FloatingNav";
import JobPreferencesModal from "./components/JobPreferencesModal";
import ProfileCard from "./components/ProfileCard";
import ProfileStrengthCard from "./components/ProfileStrengthCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/Tabs";

const navItems = [
	{
		id: "profile",
		label: "Preferences",
		icon: <PreferencesIcon className="w-5 h-5" />,
	},
	{
		id: "job-recommendations",
		label: "Job Picks",
		icon: <JobsIcon className="w-5 h-5" />,
	},
	// {
	// 	id: "documents",
	// 	label: "Documents",
	// 	icon: <DocumentIcon className="w-5 h-5" />,
	// },
	{
		id: "resume",
		label: "Resume",
		icon: <ResumeIcon className="w-5 h-5" />,
	},
];

function Profile() {
	const resumeDataService = React.useMemo(() => new ResumeDataService(), []);
	const dispatch = useDispatch();
	const data = useSelector((state) => state.SettingsReducer);
	const user = data.general;
	const { data: response, isLoading } = useLatestJobs();
	const [recommendedJobs, setRecommendedJobs] = React.useState([]);
	const [savedJobs, setSavedJobs] = React.useState([]);
	const [showPreferencesModal, setShowPreferencesModal] = React.useState(false);
	const [completenessPercentage, setCompletenessPercentage] = React.useState(0);
	const [profileData] = useState({
		name: user.firstName,
		jobTitle: "Senior Product Designer",
		location: "Dar es Salaam, Tanzania",
		status: "Open to Opportunities",
		avatarUrl: "/images/john-doe-avatar.jpg",
		skills: ["UI/UX Design", "Figma", "Adobe XD", "Prototyping"],
		preferredJobTypes: ["Full-time", "Remote"],
		preferredIndustries: ["Technology", "E-commerce", "Health Tech"],
	});
	const [loading, setLoading] = React.useState(true);
	const [resumeTitle, setResumeTitle] = React.useState("");
	const [resumeList, setResumeList] = React.useState([]);
	const [, setError] = React.useState([]);
	const storedResumes = localStorage.getItem("resumeList");
	const storedJobPreferences = localStorage.getItem("jobPreferences");
	const [jobPreferences, setJobPreferences] = React.useState({});
	const [persistJobPreferences, setPersistJobPreferences] = React.useState({});
	const [jobPreferencesBody, setJobPreferencesBody] = React.useState({});
	const { resumeData, setResumeData } = useContext(ResumeContext);
	const {
		cookie: { resume_id: activeResumeId },
		setResumeIdCookie,
	} = useSetResumeIdCookie();

	function onSuccess(response) {
		if ([...response.data].length === 0) {
			setResumeList([]);
		} else {
			const resumes = response.data;
			setResumeList(resumes);
			localStorage.setItem("resumeList", JSON.stringify(resumes));

			// sort by resume.journal.lastUpdatedOn
			const sortedByLastUpdated = [...resumes].sort((a, b) => {
				return new Date(b.journal.lastUpdatedOn) - new Date(a.journal.lastUpdatedOn);
			});

			const lastUpdatedResume = sortedByLastUpdated[0];

			setResumeIdCookie(lastUpdatedResume.id);
			fetchResumeData(lastUpdatedResume.id);
		}
	}

	function onError(error) {
		const errorMessages = error.response.data.message;
		setError(errorMessages);
	}

	const dispatchAddExtras = (payload) => {
		dispatch({ type: "ADD_EXTRAS", payload: payload });
	};

	function fetchResumeData(resumeId) {
		resumeDataService.getResumeLedgerById(
			resumeId,
			(resume) => {
				resumeObjectToContextFormat(resume.data, setResumeData, dispatchAddExtras);
				dispatch({ type: "RESUME_DATA", payload: resume.data });
				setLoading(false);
			},
			() => {},
		);
	}

	const fetchResumeList = React.useCallback(async () => {
		if (storedResumes) {
			const storedResumesData = JSON.parse(storedResumes);
			setResumeList(storedResumesData);

			const activeResume = activeResumeId
				? storedResumesData.find((resume) => resume.id === activeResumeId)
				: storedResumesData[0];

			fetchResumeData(activeResume.id);
			setLoading(true);
		} else {
			await resumeDataService.getUserResumes(onSuccess, onError);
		}
	}, [storedResumes, resumeDataService]);

	const onSuccessSettings = React.useCallback(
		(response) => {
			const general = {
				...data.general,
				email: response?.data?.emailAddress,
			};
			const primaryResume = resumeList.filter(
				(item) => item._id === response?.data?.jobPreferences?.primaryResumeReferenceId,
			);
			if (primaryResume.length === 1) {
				const resumeTitle = primaryResume[0]?.journal?.resumeTitle || primaryResume[0]?.heading?.resumeTitle;
				setResumeTitle(resumeTitle);
			}

			dispatch({ type: "EDIT_ GENERAL", payload: general });
			setJobPreferences(response?.data?.jobPreferences);
			setPersistJobPreferences(response?.data?.jobPreferences);
			setJobPreferencesBody({
				emailAddress: response?.data?.emailAddress,
				phoneNumber: data?.general?.phone,
				jobPreferences: {
					primaryResumeReferenceId: response?.data?.jobPreferences?.primaryResumeReferenceId,
					currentJobsearchStatus: response?.data?.jobPreferences?.currentJobsearchStatus,
					waysToHelpme: response?.data?.jobPreferences?.waysToHelpme,
					openForWorkTypes: response?.data?.jobPreferences?.openForWorkTypes,
					areasOfExpertise: response?.data?.jobPreferences?.areasOfExpertise,
					locationPreferenceType: response?.data?.jobPreferences?.locationPreferenceType,
					locationsPreferred: response?.data?.jobPreferences?.locationsPreferred,
					seniorityLevel: response?.data?.jobPreferences?.seniorityLevel,
				},
			});
			if (response.data) {
				localStorage.setItem("jobPreferences", JSON.stringify(response?.data));
			}
			setCompletenessPercentage(response?.data?.job_preference_progress_status?.completeness_pct);
			setLoading(false);
		},
		[data, dispatch, resumeList],
	);

	const onErrorSettings = React.useCallback(() => {
		window.location.replace("/signin");
	}, []);

	const fetchUsersAccount = React.useCallback(() => {
		setLoading(true);
		if (storedJobPreferences) {
			const storedData = JSON.parse(storedJobPreferences);
			const general = {
				...data.general,
				email: storedData?.emailAddress,
			};
			const primaryResume = resumeList.filter(
				(item) => item._id === storedData?.jobPreferences?.primaryResumeReferenceId,
			);
			if (primaryResume.length === 1) {
				const resumeTitle = primaryResume[0]?.journal?.resumeTitle || primaryResume[0]?.heading?.resumeTitle;
				setResumeTitle(resumeTitle);
			}

			dispatch({ type: "EDIT_ GENERAL", payload: general });
			setJobPreferences(storedData?.jobPreferences);
			setPersistJobPreferences(storedData?.jobPreferences);
			setJobPreferencesBody({
				emailAddress: storedData?.emailAddress,
				phoneNumber: data?.general?.phone,
				jobPreferences: {
					primaryResumeReferenceId: storedData?.jobPreferences?.primaryResumeReferenceId,
					currentJobsearchStatus: storedData?.jobPreferences?.currentJobsearchStatus,
					waysToHelpme: storedData?.jobPreferences?.waysToHelpme,
					openForWorkTypes: storedData?.jobPreferences?.openForWorkTypes,
					areasOfExpertise: storedData?.jobPreferences?.areasOfExpertise,
					locationPreferenceType: storedData?.jobPreferences?.locationPreferenceType,
					locationsPreferred: storedData?.jobPreferences?.locationsPreferred,
					seniorityLevel: storedData?.jobPreferences?.seniorityLevel,
				},
			});
			setCompletenessPercentage(storedData?.job_preference_progress_status?.completeness_pct);
			setLoading(false);
		} else {
			resumeDataService.getUser(onSuccessSettings, onErrorSettings);
		}
	}, [resumeDataService, onSuccessSettings, onErrorSettings, dispatch, data, resumeList, storedJobPreferences]);

	const handleSavePreferences = () => {
		setShowPreferencesModal(false);
	};

	const onSelectResume = (resumeId) => {
		setLoading(true);
		setResumeIdCookie(resumeId);
		fetchResumeData(resumeId);
	};

	React.useEffect(() => {
		fetchResumeList();
	}, [fetchResumeList]);

	React.useEffect(() => {
		fetchUsersAccount();
	}, [fetchUsersAccount]);

	React.useEffect(() => {
		if (!isLoading && response) {
			if (completenessPercentage > 0) {
				setRecommendedJobs(response.data);
			}
			setSavedJobs(response.data);
		}
	}, [response, isLoading, completenessPercentage > 0]);

	return (
		<>
			<Header activeLink="Profile" />
			<div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
				<FloatingNav items={navItems} />

				{/* Profile Section */}
				<section id="profile" className="scroll-mt-24">
					<ProfileCard
						{...profileData}
						completenessPercentage={completenessPercentage}
						onEditPreferences={() => setShowPreferencesModal(true)}
					/>
				</section>

				{/* Job Preferences Modal */}
				<JobPreferencesModal
					isOpen={showPreferencesModal}
					onClose={() => setShowPreferencesModal(false)}
					persistJobPreferences={persistJobPreferences}
					jobPreferencesBody={jobPreferencesBody}
					setJobPreferences={setJobPreferences}
					setPersistJobPreferences={setPersistJobPreferences}
					setError={setError}
					setJobPreferencesBody={setJobPreferencesBody}
					jobPreferences={jobPreferences}
					resumeTitle={resumeTitle}
					onSave={handleSavePreferences}
				/>

				{/* Job Recommendations Section */}
				<section id="job-recommendations" className="scroll-mt-24 mt-12">
					<div className="mb-4">
						<h2 className="text-xl font-bold text-gray-800 font-plus-jakarta">Job picks for you</h2>
					</div>

					<Tabs defaultValue="recommended" className="w-full">
						<TabsList className="flex border-b-0">
							<TabsTrigger
								value="recommended"
								className="ignore-focus-behavior rounded-none text-sm text-gray-700 mr-6"
							>
								Recommended
							</TabsTrigger>
							<TabsTrigger value="saved" className="ignore-focus-behavior rounded-none text-sm text-gray-700">
								Saved Jobs
							</TabsTrigger>
						</TabsList>

						<TabsContent value="recommended">
							{completenessPercentage === 0 && (
								<div className="bg-blue-50/50 rounded-lg p-6 border border-blue-100 h-full flex flex-col justify-between">
									<div className="flex justify-between items-center gap-8">
										<div className="flex-[2]">
											<span className="text-sm font-medium text-primary-600">Profile Completion</span>
											<h2 className="text-xl font-semibold text-gray-900 mt-1 mb-2">Get better job matches</h2>
											<p className="text-gray-800 text-sm mb-8">
												Complete your profile to receive personalized job recommendations that match your skills and
												preferences.
											</p>
										</div>
										<div className="flex-1">
											<div className="w-full aspect-[2/1] relative">
												<img
													alt="Complete your profile"
													loading="lazy"
													decoding="async"
													data-nimg="fill"
													className="object-contain absolute h-full w-full left-0 top-0 bottom-0 right-0"
													src="/images/solo-creation-transparent.png"
												/>
											</div>
										</div>
									</div>
									<Link
										className="inline-flex items-center justify-center bg-white text-gray-700 px-4 h-9 rounded hover:bg-gray-50 transition-colors text-sm font-medium border border-gray-300 w-full mt-8"
										onClick={() => setShowPreferencesModal(true)}
									>
										Complete Profile
									</Link>
								</div>
							)}
							{completenessPercentage > 0 && (
								<>
									{isLoading && (
										<div className="animate-pulse">
											{[...new Array(3)].map((_, index) => (
												<div className="h-24 bg-gray-900 opacity-10 mb-3 rounded-md" key={index} />
											))}
										</div>
									)}
									{!isLoading && (
										<div className="divide-y divide-gray-200">
											{recommendedJobs.slice(1, 5).map((job, index) => (
												<JobListingItem
													key={index}
													job={{
														...job,
														logo: job.company.logo_url,
														company: job.companyName,
													}}
													onApply={() => {
														/* Handle apply action */
													}}
													onClick={() => {
														/* Handle click action */
													}}
													showCompany={true}
													titleClassName="text-base"
												/>
											))}
										</div>
									)}
								</>
							)}
						</TabsContent>

						<TabsContent value="saved">
							{isLoading && (
								<div className="animate-pulse">
									{[...new Array(3)].map((_, index) => (
										<div className="h-24 bg-gray-900 opacity-10 mb-3 rounded-md" key={index} />
									))}
								</div>
							)}
							{!isLoading && savedJobs.length > 0 ? (
								<div className="divide-y divide-gray-200">
									{savedJobs.slice(1, 3).map((job, index) => (
										<JobListingItem
											key={index}
											job={{
												...job,
												logo: job.company.logo_url,
												company: job.companyName,
											}}
											onApply={() => {
												/* Handle apply action */
											}}
											onClick={() => {
												/* Handle click action */
											}}
											showCompany={true}
											titleClassName="text-base"
										/>
									))}
								</div>
							) : (
								<div className="text-center py-8 text-gray-500">
									No saved jobs yet. Save jobs to view them here later.
								</div>
							)}
						</TabsContent>
					</Tabs>

					{completenessPercentage > 0 && (
						<div className="mt-8">
							<Link to="/jobs">
								<div className="w-full py-2 px-4 bg-white text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors duration-200 text-sm font-inter text-center">
									View all jobs
								</div>
							</Link>
						</div>
					)}
				</section>

				{/* Resume Section */}
				<section id="resume" className="scroll-mt-24">
					<ProfileStrengthCard
						loading={loading}
						resumeData={resumeData}
						resumeTitle={resumeTitle}
						resumeList={resumeList}
						setResumeTitle={setResumeTitle}
						onSelectResume={onSelectResume}
					/>
				</section>
			</div>
		</>
	);
}

export default Profile;
