import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useFeaturedEmployers } from "../../../../services/create-resume/queries";

function FeaturedEmployers() {
	const [employers, setEmployers] = React.useState([]);
	const { data: response, isLoading } = useFeaturedEmployers();

	useEffect(() => {
		if (!isLoading && response) {
			setEmployers(response.data);
		}
	}, [isLoading, response]);

	const navigate = useNavigate();

	return (
		<section
			className="pt-20 pb-12 px-4 md:px-8"
			style={{ background: "linear-gradient(180deg, #F0F7FF 0%, #FFFFFF 100%)" }}
		>
			<div className="max-w-6xl mx-auto">
				<h2 className="text-3xl font-bold mb-8 text-gray-900 font-plus-jakarta">Featured Employers</h2>
				{isLoading ? (
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 animate-pulse">
						{[...new Array(6)].map((_, index) => (
							<div key={index} className="bg-gray-900 opacity-10 h-96 rounded-md shadow-sm" />
						))}
					</div>
				) : (
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
						{employers.map((company, index) => (
							<div className="bg-white rounded-md p-6 flex flex-col h-full shadow-sm" key={index}>
								<div className="flex items-center mb-3">
									<div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0 border border-gray-100 flex items-center justify-center">
										<img
											alt="TechCorp Tanzania logo"
											loading="lazy"
											width="64"
											height="64"
											decoding="async"
											data-nimg="1"
											className="w-full h-full object-cover"
											src={
												company.companyDetails
													? company.companyDetails.logo_url
													: "https://picsum.photos/200?random=" + index
											}
										/>
									</div>
									<h3 className="ml-4 text-xl font-semibold font-inter text-gray-900">
										{company.companyDetails ? company.companyDetails.company_name : company.jobs[0].companyName}
									</h3>
								</div>
								<p className="text-gray-700 mb-3 font-inter">Leading technology solutions provider in Tanzania</p>
								<div className="flex flex-wrap gap-2 mb-3">
									{company.companyDetails && (
										<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700 border border-gray-200 font-inter">
											{company.companyDetails.company_industry}
										</span>
									)}

									{company.companyDetails && (
										<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700 border border-gray-200 font-inter">
											{company.companyDetails.company_size_approx + " employees"}
										</span>
									)}

									{company.companyDetails && (
										<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700 border border-gray-200 font-inter">
											{company.companyDetails.headquarters}
										</span>
									)}
								</div>
								<div className="flex-grow flex flex-col justify-between">
									<div className="space-y-2 py-2 -mx-2">
										{company.jobs.map((job) => (
											<div className="rounded-md transition-colors hover:bg-gray-50 px-2 py-1" key={job._id}>
												<a
													className="text-primary-700 hover:text-primary-800 font-medium font-inter text-base"
													href="/jobs/1-0"
												>
													{job.jobTitle}
												</a>
												<p className="text-xs text-gray-600 font-inter mt-1">
													{job.jobLocation.city}, {job.jobLocation.region} • 3 days ago
												</p>
											</div>
										))}
									</div>
									<div className="mt-4 flex space-x-2">
										<span
											className="flex-1 bg-gray-100 text-gray-700 px-4 h-9 !rounded hover:bg-gray-200 transition-colors font-inter text-center flex items-center justify-center text-sm font-medium"
											onClick={() =>
												navigate(`/companies/${company.company}`, {
													state: { company },
												})
											}
										>
											View Profile
										</span>
										<button className="flex-1 bg-white border border-gray-300 text-gray-700 px-4 h-9 !rounded hover:bg-gray-50 transition-colors font-inter text-center flex items-center justify-center text-sm font-medium">
											Follow
										</button>
									</div>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</section>
	);
}

export default FeaturedEmployers;
