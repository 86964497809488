import React, { useState } from "react";

function SaveButton({ onClick = () => {} }) {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<button
			className="inline-flex items-center justify-center !bg-gray-200 text-gray-700 h-9 rounded hover:!bg-gray-300 transition-colors text-sm font-inter w-[70px] relative overflow-hidden"
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			onClick={onClick}
		>
			<span
				className={`transition-all duration-300 ${isHovered ? "opacity-0 transform -translate-y-8" : "opacity-100"}`}
			>
				Save
			</span>
			<span
				className={`absolute inset-0 flex items-center justify-center transition-all duration-300 ${isHovered ? "opacity-100 transform translate-y-0" : "opacity-0 transform translate-y-8"}`}
			>
				<i className="fa-regular fa-bookmark" />
			</span>
		</button>
	);
}

export default SaveButton;
