import dayjs from "dayjs";
import React, { useState } from "react";

import { CalendarIcon, DollarSign, MapPinIcon } from "../../../assets/icons/interface/interface";
import SaveButton from "../../../components/SaveButton";

const JobListingItem = ({
	job,
	onApply,
	onClick,
	showCompany = false,
	titleClassName = "",
	hideSaveButton = false,
	showMatchScore = false,
	hideDate = false,
	hideCategory = false,
}) => {
	const [isItemHovered, setIsItemHovered] = useState(false);

	return (
		<div
			className={`flex justify-between items-center py-4 border-gray-200 transition-colors duration-300 ${isItemHovered ? "bg-gray-50" : ""}`}
			onMouseEnter={() => setIsItemHovered(true)}
			onMouseLeave={() => setIsItemHovered(false)}
		>
			<div className="flex items-center cursor-pointer" onClick={onClick}>
				{job?.logo && (
					<div className="w-12 h-12 rounded-lg mr-4 flex items-center justify-center overflow-hidden border border-gray-100">
						<img
							src={job?.logo}
							alt={`${job?.company} logo`}
							width={48}
							height={48}
							className="w-full h-full object-contain"
						/>
					</div>
				)}
				<div>
					<div className="flex items-center mb-1">
						<h3
							className={`${titleClassName || "text-base"} font-semibold mr-2 font-inter transition-colors duration-300 ${isItemHovered ? "text-primary-700" : "text-gray-900"}`}
						>
							{job?.jobTitle}
						</h3>
						{!hideCategory && job?.category && (
							<span className="text-xs px-2 rounded bg-gray-100 text-gray-800 h-5 flex items-center justify-center mr-2">
								{job?.category}
							</span>
						)}
						{job?.isPromoted && (
							<span className="text-xs px-2 rounded bg-blue-100 text-blue-800 h-5 flex items-center justify-center">
								Promoted
							</span>
						)}
					</div>
					<p className="text-[14px] text-gray-600 flex items-center flex-wrap">
						{showMatchScore && job?.matchPercentage && (
							<span className="inline-flex items-center h-6 px-2.5 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 border border-green-200 mr-3">
								{job?.matchPercentage}% Match
							</span>
						)}
						{showCompany && <span className="mr-3">{job?.company}</span>}
						<span className="flex items-center mr-3">
							<MapPinIcon className="w-4 h-4 mr-1" />
							{job?.jobLocation?.city}, {job?.jobLocation?.country}
						</span>
						{!hideDate && (
							<span className="flex items-center mr-3">
								<CalendarIcon className="w-4 h-4 mr-1" />
								{dayjs(`${job?.deadlineDate.year}-${job?.deadlineDate.month}-${job?.deadlineDate.day}`).format(
									"MMM DD, YYYY",
								)}
							</span>
						)}
						{!hideSaveButton && job?.salary && (
							<span className="flex items-center">
								<DollarSign className="w-4 h-4 mr-1" />
								{job?.salary?.payCurrency} {job?.salary?.minPay} - {job?.salary?.payCurrency} {job?.salary?.maxPay}{" "}
								{job?.salary?.payFrequency}
							</span>
						)}
					</p>
				</div>
			</div>
			<div className="flex items-center space-x-2">
				{!hideSaveButton && <SaveButton />}
				{onApply && (
					<button
						onClick={onApply}
						className="inline-flex items-center justify-center bg-primary-700 text-white px-4 h-9 rounded hover:bg-primary-800 transition-colors duration-300 text-sm font-inter w-[90px] group relative overflow-hidden"
					>
						<span className="mr-1 group-hover:mr-3 transition-all duration-300">Apply</span>
						<span className="absolute right-4 opacity-0 transition-all duration-300 transform translate-x-2 group-hover:opacity-100 group-hover:translate-x-0">
							›
						</span>
					</button>
				)}
			</div>
		</div>
	);
};

export default JobListingItem;
