import React, { useState } from "react";

import SearchableSelectInput from "../SearchableSelectInput/SearchableSelectInput";
import TextEditor from "../TextEditor";

function EditableText({ value, onChange, type, options }) {
	const [editing, setEditing] = useState(false);

	const [inputValue, setInputValue] = useState(value);
	const [appendedItem, setAppendedItem] = useState("");

	const handleBlur = () => {
		setEditing(false);
		onChange(inputValue);
	};

	const handleAppendedItem = (type, index = null) => {
		if (type === "ADD") {
			if (appendedItem.trim() !== "") {
				onChange(value.concat(appendedItem));
				setAppendedItem("");
			}
			setEditing(false);
		}

		if (type === "REMOVE") onChange(value.filter((_, i) => i !== index));
	};

	const formatedString = (value) => {
		if (Array.isArray(value)) {
			return value.length === 0
				? "---"
				: value.map((item, index) => `${item} ${value.length - (index + 1) === 0 ? "" : "|"} `);
		}

		if (typeof value === "string") {
			const doc = new DOMParser().parseFromString(value, "text/html");
			return doc.body.textContent || "";
		}

		return value;
	};

	return (
		<div>
			{editing ? (
				type === "select" ? (
					<SearchableSelectInput
						defaultLabel="Select"
						value={inputValue}
						options={options}
						onSelect={(value) => {
							setInputValue(value);
							onChange(value);
							setEditing(false);
						}}
					/>
				) : type === "textEditor" ? (
					<TextEditor
						value={inputValue}
						id="aboutUsEdit"
						setValue={(value) => {
							setInputValue(value);
							onChange(value);
						}}
						placeholder="Add Company Description here..."
					/>
				) : type === "appendedItems" ? (
					<>
						<div>
							{value.map((item, index) => {
								return (
									<span key={index} className="mr-1">
										{item}
										<i
											className="bi bi-trash3 fs-12 lh-1 text-danger ml-2 cursor-pointer"
											onClick={() => handleAppendedItem("REMOVE", index)}
										/>{" "}
										{value.length - (index + 1) === 0 ? null : "|"}
									</span>
								);
							})}
						</div>
						<div className="custom-input-skill-container mt-1" style={{ width: 590 + "px" }}>
							<input
								type="text"
								value={appendedItem}
								onChange={(e) => setAppendedItem(e.target.value)}
								onKeyDown={(e) => e.key === "Enter" && handleAppendedItem("ADD")}
								className="custom-input-skill"
								style={{ width: 520 + "px" }}
							/>
							<div className="end-icon-custom-input-skill-container" onClick={() => handleAppendedItem("ADD")}>
								<span className="fa fa-check text-white" />
							</div>
						</div>
					</>
				) : (
					<input
						type="text"
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						onBlur={handleBlur}
						onKeyDown={(e) => e.key === "Enter" && handleBlur()}
						autoFocus
						className="box-border w-full rounded-sm border border-gray-200 bg-white p-2 text-sm leading-none focus:border-gray-200 focus:outline-none focus:ring-0"
					/>
				)
			) : (
				<div onClick={() => setEditing(true)}>{formatedString(value)}</div>
			)}
		</div>
	);
}

export default EditableText;
