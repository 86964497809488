import React, { useEffect, useState } from "react";

import EditableText from "../../../components/EditableText";
import Loader from "../../../components/Loader";
import { checkObjectsHaveSameKeysAndValues } from "../../../handlers/utilityFunctions";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import DetailSection from "./DetailSection";

function ViewCompany({ selectedItem, setShowProfile, onUpdate, onSubmit, loadingAction }) {
	const [, setErrors] = useState(null);
	const [profileData, setProfileData] = useState({});
	const [loading, setLoading] = useState(false);
	const companyService = new ResumeDataService();

	const onChange = (key, value) => {
		setProfileData((prev) => ({ ...prev, [key]: value }));
		onUpdate(key, value);
	};

	function getCompanyDetails(companyId) {
		setLoading(true);
		companyService.getCompanies(
			companyId,
			"",
			"",
			(response) => {
				const _selectedItem = { ...response.data };
				_selectedItem.locations = [];
				_selectedItem.locations =
					_selectedItem.locations.length === 0 ? [] : _selectedItem.locations.map((location) => location.value);
				_selectedItem.website = _selectedItem.website || _selectedItem.company_website;
				setProfileData(_selectedItem);
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				setErrors((prev) => ({ ...prev, ...error.response.data.message }));
			},
		);
	}

	useEffect(() => {
		getCompanyDetails(selectedItem._id);
	}, []);

	return (
		<div className="fixed top-0 bottom-0 left-0 right-0 h-full w-full" style={{ background: "rgba(0,0,0,0.4)" }}>
			<div className="fixed left-0 lg:left-auto right-0 lg:w-5/12 top-0 z-30 h-full rounded bg-white shadow-lg">
				<div className="flex justify-end">
					<button onClick={() => setShowProfile(false)} className="aspect-h-1 aspect-w-1 p-4 hover:bg-gray-100">
						<i className="bi bi-x-lg text-gray-950" />
					</button>
				</div>
				<div className="p-4 h-full overflow-y-auto">
					<div className="flex items-center space-x-4 mb-12">
						{selectedItem.logo_url !== " " ? (
							<div className="w-20">
								<img
									alt="Company Logo"
									src={selectedItem.logo_url}
									onError={(e) => (e.target.src = "/images/co-placeholder.png")}
								/>
							</div>
						) : (
							<div className="mb-2 flex h-16 w-16 items-center justify-center rounded-full bg-primary-600 text-xl text-white">
								{selectedItem.company_name.split(" ").map((name) => name[0].toUpperCase())}
							</div>
						)}
						<div className="mr-auto">
							<h5 className="mb-2">
								<EditableText value={selectedItem.company_name} onChange={(value) => onChange("company_name", value)} />
							</h5>
							<EditableText
								value={selectedItem.tagLine || "Company motives goes here"}
								onChange={(value) => onChange("tagLine", value)}
							/>
						</div>
					</div>

					{loading ? (
						<Loader />
					) : (
						<DetailSection
							profileData={profileData}
							onChange={onChange}
							hasEditDetails={!checkObjectsHaveSameKeysAndValues(selectedItem, profileData)}
							onSubmit={onSubmit}
							setShowProfile={setShowProfile}
							loadingAction={loadingAction}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default ViewCompany;
