import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";

import ResumeContext from "../../../context/ResumeContext";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import ResumeFeedbackModal from "../../../Views/ResumeBuilder/ResumeFeedbackModal";
import CandidateSupportModal from "../../CandidateSupportModal/CandidateSupportModal";
import Icon from "../../Icon";
import ResumeProgress from "../ResumeProgress";

const ResumeBuilderLayout = () => {
	const { resumeData } = useContext(ResumeContext);

	const currentRoute = window.location.pathname.split("/")[2];

	const navigate = useNavigate();

	const [currentRouteName, setCurrentRouteName] = useState("");
	const [nextRoute, setNextRoute] = useState({
		section: "work-history-summary",
		title: "Work History",
	});

	useEffect(() => {
		const data = [
			{ section: "select-experience", title: "Select Experience" },
			{ section: "select-template", title: "Select Template" },
			{ section: "contact-information", title: "Personal Information" },
			{ section: "work-history-summary", title: "Work History" },
			{ section: "education-summary", title: "Education" },
			{ section: "skills", title: "Skills" },
			{ section: "brief-summary", title: "Professional Summary" },
			{ section: "extras", title: "Extras" },
			{ section: "personal-links", title: "Personal Links" },
			{ section: "accomplishments", title: "Accomplishments" },
			{ section: "affiliations", title: "Affiliations" },
			{ section: "certifications", title: "Certifications" },
			{ section: "languages", title: "Languages" },
			{ section: "additional-information", title: "Additional Information" },
			{ section: "reference-summary", title: "References" },
			{ section: "personal-profile", title: "Personal Profile" },
			{ section: "custom-extra", title: "Custom Extra" },
			// below here don't show in the progress bar
			{ section: "work-history", title: "Work History", exclude: true },
			{ section: "work-details", title: "Work Details", exclude: true },
			{ section: "education-information", title: "Education Information", exclude: true },
			{ section: "reference-information", title: "Reference Information", exclude: true },
			{ section: "review", title: "Review", progressStatus: "INCOMPLETE" },
		];

		// remove null values
		const filteredData = data.filter((item) => item !== null);

		const thisRoute = filteredData.find((item) => currentRoute === item.section);
		const thisRouteName = thisRoute.title;
		setCurrentRouteName(thisRouteName);

		const nextRouteIndex = filteredData.findIndex((item) => currentRoute === item.section) + 1;

		const nextRoute =
			filteredData[nextRouteIndex] && !filteredData[nextRouteIndex].exclude
				? filteredData[nextRouteIndex]
				: {
						section: "review",
						title: "Review",
					};

		if (!thisRoute.exclude) {
			setNextRoute(nextRoute);
		} else {
			setNextRoute({ section: currentRoute, title: "" });
		}
	}, [resumeData, currentRoute]);

	const [resumeSections, setResumeSections] = React.useState([]);
	const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
	const dispatch = useDispatch();
	const { feedBacks } = useSelector((state) => state.FeedbackReducers);

	useEffect(() => {
		const data = [
			{
				section: "contact-information",
				title: "Personal Information",
				progressStatus: resumeData.progressStatus.HEADING,
			},
			{
				section: "work-history-summary",
				title: "Work History",
				progressStatus: resumeData.progressStatus.EXPERIENCE,
			},
			{ section: "education-summary", title: "Education", progressStatus: resumeData.progressStatus.EDUCATION },
			{ section: "skills", title: "Skills", progressStatus: resumeData.progressStatus.SKILLS },
			{
				section: "brief-summary",
				title: "Professional Summary",
				progressStatus: resumeData.progressStatus.SUMMARY,
			},
			[...resumeData.extras.personalLinks].length > 0
				? {
						section: "personal-links",
						noClick: true,
						title: "Personal Links",
						progressStatus: "COMPLETED",
						isExtraSection: true,
					}
				: null,
			resumeData.extras.accomplishments
				? {
						noClick: true,
						section: "accomplishments",
						title: "Accomplishments",
						progressStatus: "COMPLETED",
						isExtraSection: true,
					}
				: null,
			resumeData.extras.affiliations
				? {
						noClick: true,
						section: "affiliations",
						title: "Affiliations",
						progressStatus: "COMPLETED",
						isExtraSection: true,
					}
				: null,
			resumeData.extras.certifications
				? {
						section: "certifications",
						noClick: true,
						title: "Certifications",
						progressStatus: "COMPLETED",
						isExtraSection: true,
					}
				: null,

			[...resumeData.extras.languages].length > 0
				? {
						noClick: true,
						section: "languages",
						title: "Languages",
						progressStatus: "COMPLETED",
						isExtraSection: true,
					}
				: null,
			resumeData.extras.additionalInfo
				? {
						section: "additional-information",
						noClick: true,
						title: "Additional Information",
						progressStatus: "COMPLETED",
						isExtraSection: true,
					}
				: null,
			[...resumeData.extras.references].length > 0
				? {
						section: "reference-summary",
						noClick: true,
						title: "References",
						progressStatus: "COMPLETED",
						isExtraSection: true,
					}
				: null,
			resumeData.extras.customExtras.data !== ""
				? {
						section: "custom-extra/" + resumeData.extras.customExtras.name,
						title: resumeData.extras.customExtras.name,
						progressStatus: resumeData.extras.customExtras.data !== "" ? "COMPLETED" : "INCOMPLETE",
						isExtraSection: true,
					}
				: null,
			resumeData.extras.personalProfile.dateOfBirth
				? {
						section: "personal-profile",
						noClick: true,
						title: "Personal Profile",
						progressStatus: "COMPLETED",
						isExtraSection: true,
					}
				: null,
			{ section: "review", title: "Review", progressStatus: "INCOMPLETE" },
		];

		// remove null values
		const filteredData = data.filter((item) => item !== null);

		setResumeSections(filteredData);
	}, [resumeData]);

	const [isMobile, setIsMobile] = useState(window.innerWidth < 769);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 769);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const [feedbackLoading, setFeedbackLoading] = useState(false);
	const resumeDataService = new ResumeDataService();

	const dispatchFeedbackState = (name, value) => {
		dispatch({ type: "UPDATE_FEEDBACK", payload: { name, value } });
	};

	function successGetResumeReviews(response) {
		const _feedBacks = [];
		const feedBackDetails = response.data;

		for (let feedBackDetail in feedBackDetails) {
			feedBackDetails[feedBackDetail].forEach((feedBackDetail) => {
				_feedBacks.push(feedBackDetail);
			});
		}

		setFeedbackLoading(false);
		dispatchFeedbackState("feedBacks", _feedBacks);
		dispatchFeedbackState("feedBackSearched", true);
	}

	function getReviewFeedBack(reviewRequestId) {
		setFeedbackLoading(true);
		dispatchFeedbackState("feedBacks", []);
		dispatchFeedbackState("feedBackSearched", false);

		resumeDataService.getResumeFeedback(reviewRequestId, successGetResumeReviews, () => {
			setFeedbackLoading(false);
			toast.error("Something went wrong!");
			dispatchFeedbackState("feedBackSearched", true);
		});
	}

	function handleFeedbackModal() {
		if (!isFeedbackOpen && feedBacks.length === 0) {
			if (resumeData.reviewRequests) {
				const _reviewRequests = resumeData.reviewRequests.filter(
					(reviewRequest) => reviewRequest.status === "PUBLISHED",
				);

				getReviewFeedBack(_reviewRequests[0]._id);
			}
		}

		setIsFeedbackOpen(!isFeedbackOpen);
	}

	const [openNeedHelpModal, setOpenNeedHelpModal] = useState(false);
	const toggleNeedHelp = () => {
		setOpenNeedHelpModal(!openNeedHelpModal);
	};

	if (isMobile) {
		return (
			<div className="resume-builder-content">
				<nav className="bg-white border-bottom p-3 d-flex mb-3 top-0 w-100 align-items-center justify-content-between">
					<div className="d-flex">
						<Link to="/">
							<Icon iconName="home" />
						</Link>
					</div>
					<div className="d-flex">
						<button className="text-primary font-weight-medium mr-2" data-toggle="modal" data-target="#ResumePreview">
							<Icon iconName="eye-primary" className="mr-1" />
						</button>
						<button className="text-primary font-weight-medium mr-2">
							<Icon iconName="clipboard-primary" className="mr-1" />
						</button>
						<button className="text-primary font-weight-medium" onClick={() => toggleNeedHelp()}>
							<Icon iconName="flag-primary" className="mr-1" />
						</button>
					</div>
				</nav>
				<div className="container">
					<button onClick={() => navigate("/my-resumes")} className="p-0 mb-3">
						<Icon iconName="back-arrow" size={26} />
					</button>

					<p className="fs-18 font-weight-bold text-black">{currentRouteName}</p>
				</div>
				<div className="outlet-container">
					<Outlet />
				</div>

				<div className="mobile-resume-bottom-bar">
					<div className="d-flex align-items-center justify-content-between">
						{resumeSections.slice(0, 5).map((item, index) => (
							<span key={index} onClick={() => !item.noClick && navigate(`/resume/${item.section}`)}>
								<i
									className={`bi mr-1 lh-1 fs-16 ${item.progressStatus === "COMPLETED" ? "bi-check-circle-fill text-success" : "bi-circle text-light-blue"}`}
								/>
							</span>
						))}
					</div>
					<div className="">
						{nextRoute.title && (
							<button
								className="text-primary font-weight-semi pr-0 lh-1"
								onClick={() => navigate(`/resume/${nextRoute.section}`)}
							>
								{nextRoute.title}
								<i className="bi bi-chevron-right ml-3 text-dark" />
							</button>
						)}
					</div>
				</div>
				{openNeedHelpModal && <CandidateSupportModal onCloseNeedHelpModal={toggleNeedHelp} />}
			</div>
		);
	}

	return (
		<div>
			<ResumeProgress resumeSections={resumeSections} />

			<section className="bg-white resume-builder-content" style={{ marginLeft: "240px" }}>
				<nav className="bg-white border-bottom p-3 d-flex mb-3 top-0 w-100 align-items-center justify-content-between">
					<div className="d-flex">
						<Link to="/">
							<Icon iconName="home" />
						</Link>
						<p className="m-0 ml-2 font-weight-bold text-black">{currentRouteName}</p>
					</div>
					<div className="d-flex">
						<button className="text-primary font-weight-medium mr-2" data-toggle="modal" data-target="#ResumePreview">
							<Icon iconName="eye-primary" className="mr-1" /> Preview
						</button>
						<button className="text-primary font-weight-medium mr-2" onClick={() => handleFeedbackModal()}>
							<Icon iconName="clipboard-primary" className="mr-1" /> Review feedback
						</button>
						<button className="text-primary font-weight-medium" onClick={() => toggleNeedHelp()}>
							<Icon iconName="flag-primary" className="mr-1" /> Report issue
						</button>
					</div>
				</nav>
				<div className="container">
					<button onClick={() => navigate("/my-resumes")} className="px-1">
						<Icon iconName="back-arrow" size={26} />
					</button>
				</div>
				<Outlet />
			</section>
			<ResumeFeedbackModal
				feedbacks={feedBacks}
				isFeedbackOpen={isFeedbackOpen}
				feedbackLoading={feedbackLoading}
				toggleFeedbackModal={() => setIsFeedbackOpen(!isFeedbackOpen)}
			/>
			{openNeedHelpModal && <CandidateSupportModal onCloseNeedHelpModal={toggleNeedHelp} />}
		</div>
	);
};

export default ResumeBuilderLayout;
