"use client";

import React, { useEffect, useState } from "react";

import { cn } from "../../../handlers/cn.utils";

export default function FloatingNav({ items }) {
	const [activeSection, setActiveSection] = useState("");

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setActiveSection(entry.target.id);
					}
				});
			},
			{
				threshold: 0.3,
				rootMargin: "-20% 0px -35% 0px",
			},
		);

		items.forEach((item) => {
			const element = document.getElementById(item.id);
			if (element) observer.observe(element);
		});

		return () => observer.disconnect();
	}, [items]);

	const handleClick = (e, id) => {
		e.preventDefault();
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	};

	return (
		<nav className="fixed left-4 top-1/2 transform -translate-y-1/2 space-y-3 hidden lg:block">
			{items.map((item) => (
				<a
					key={item.id}
					href={`#${item.id}`}
					onClick={(e) => handleClick(e, item.id)}
					className={cn(
						"flex items-center group relative",
						activeSection === item.id ? "text-white" : "text-gray-600 hover:text-gray-900",
					)}
				>
					<div
						className={cn(
							"flex items-center justify-center w-[40px] h-[40px] rounded-md transition-all duration-200",
							activeSection === item.id ? "bg-gray-900" : "hover:bg-gray-100",
						)}
					>
						<div className="w-6 h-6 flex items-center justify-center">{item.icon}</div>
					</div>
					<span className="absolute left-[48px] px-2 py-1 text-sm font-medium text-gray-700 bg-white rounded-md shadow-sm border border-gray-200 opacity-0 -translate-x-2 pointer-events-none transition-all duration-200 whitespace-nowrap group-hover:opacity-100 group-hover:translate-x-0">
						{item.label}
					</span>
				</a>
			))}
		</nav>
	);
}
