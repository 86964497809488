import React from "react";

export const Card = ({ className = "", children }) => {
	return <div className={`rounded-lg border border-gray-200 bg-white shadow-sm ${className}`}>{children}</div>;
};

export const CardHeader = ({ className = "", children }) => {
	return <div className={`p-6 pt-6 ${className}`}>{children}</div>;
};

export const CardTitle = ({ className = "", children }) => {
	return <h3 className={`text-lg font-semibold ${className}`}>{children}</h3>;
};

export const CardContent = ({ className = "", children }) => {
	return <div className={`p-6 pt-6 ${className}`}>{children}</div>;
};

export const CardFooter = ({ className = "", children }) => {
	return <div className={`p-6 pt-6 ${className}`}>{children}</div>;
};
