import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
	BookmarkIcon,
	BriefcaseIcon,
	BuildingIcon,
	CalendarIcon,
	DollarSign,
	GlobeIcon,
	MapPinIcon,
	UsersIcon,
} from "../../../../assets/icons/interface/interface";
import { useLatestJobs } from "../../../../services/create-resume/queries";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";
import { Card, CardContent, CardHeader, CardTitle } from "../../../JobsPage/components/Card";
import JobListingItem from "../../../JobsPage/components/JobListingItem";
import Loader from "./Loader";

function ViewJob({ data, setData }) {
	const { id } = useParams();
	const navigate = useNavigate();
	const jobService = new ResumeDataService();
	const [jobDetails, setJobDetails] = useState({});
	const [moreJobs, setMoreJobs] = useState([]);

	const [loading, setLoading] = useState(true);

	const { data: response, isLoading } = useLatestJobs();

	React.useEffect(() => {
		if (!isLoading && response) {
			setMoreJobs(response.data);
		}
	}, [response, isLoading]);

	useEffect(() => {
		setLoading(true);
		jobService.getSelectedJob(
			data?._id || id,
			(response) => {
				setJobDetails(response.data);
				setLoading(false);
			},
			() => {
				setLoading(false);
			},
		);
	}, [data, id]);

	return (
		<>
			{loading && (
				<div className="flex items-center justify-center min-h-screen">
					<Loader />
				</div>
			)}
			{!loading && (
				<div className="min-h-screen">
					<div className="max-w-6xl mx-auto px-3 sm:px-4 lg:px-6 py-8">
						<Link className="text-primary-600 hover:text-primary-700 mb-8 inline-block text-sm" to="/jobs">
							&larr; Back to Job Listings
						</Link>
						<div className="mb-8">
							<div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6">
								<div className="flex items-center mb-4 sm:mb-0">
									<img
										alt={`${jobDetails?.companyName} logo`}
										loading="lazy"
										width="64"
										height="64"
										decoding="async"
										data-nimg="1"
										className="rounded-lg mr-4"
										style={{ color: "transparent" }}
										src={jobDetails?.company ? jobDetails?.company?.logo_url : "https://via.placeholder.com/150"}
									/>
									<div>
										<h1 className="text-2xl font-bold text-gray-900 font-plus-jakarta">{jobDetails?.jobTitle}</h1>
										<p className="text-lg text-gray-700 font-inter">{jobDetails?.companyName}</p>
									</div>
								</div>
							</div>
							<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
								<div className="flex items-center">
									<MapPinIcon className="w-5 h-5 mr-2 text-gray-500" />
									<span className="text-gray-700 text-sm">
										{jobDetails?.jobLocation?.city}, {jobDetails?.jobLocation?.region}
									</span>
								</div>
								<div className="flex items-center">
									<CalendarIcon className="w-5 h-5 mr-2 text-gray-500" />
									<span className="text-gray-700 text-sm">
										Posted on {dayjs(jobDetails?.createdAt).format("YYYY-MM-DD")}
									</span>
								</div>
								<div className="flex items-center">
									<BriefcaseIcon className="w-5 h-5 mr-2 text-gray-500" />
									<span className="text-gray-700 text-sm">{jobDetails?.employmentType}</span>
								</div>
								<div className="flex items-center">
									<UsersIcon className="w-5 h-5 mr-2 text-gray-500" />
									<span className="text-gray-700 text-sm">{jobDetails?.company?.company_size_approx} Employees</span>
								</div>
								<div className="flex items-center">
									<DollarSign className="w-5 h-5 mr-2 text-gray-500" />
									<span className="text-gray-700 text-sm">
										{jobDetails?.salary?.payCurrency}
										{jobDetails?.salary?.minPay} - {jobDetails?.salary?.payCurrency}
										{jobDetails?.salary?.maxPay}
									</span>
								</div>
							</div>
							<div className="flex items-center justify-between mb-8">
								<div className="flex items-center space-x-2">
									<button className="inline-flex items-center justify-center bg-gray-200 text-gray-700 h-9 rounded-lg hover:bg-gray-300 transition-colors text-sm font-inter w-[70px] relative overflow-hidden">
										<span className="transition-all duration-300">Save</span>
										<span className="absolute inset-0 flex items-center justify-center transition-all duration-300 opacity-0 transform translate-y-8">
											<BookmarkIcon className="w-5 h-5" />
										</span>
									</button>
									<button className="inline-flex items-center justify-center bg-primary-700 text-white px-4 h-9 rounded-lg hover:bg-primary-800 transition-colors duration-300 text-sm font-inter w-[90px] group relative overflow-hidden">
										<span className="mr-1 group-hover:mr-3 transition-all duration-300">Apply</span>
										<span className="absolute right-4 opacity-0 transition-all duration-300 transform translate-x-2 group-hover:opacity-100 group-hover:translate-x-0">
											›
										</span>
									</button>
								</div>
								<button
									onClick={() => {
										/* Handle not interested action */
									}}
									className="text-sm text-gray-500 hover:text-gray-700 transition-colors duration-300"
								>
									Not Relevant
								</button>
							</div>
							<div className="mb-8">
								<h2 className="text-base font-semibold mb-2 font-inter">Job Description</h2>
								<div
									className="text-gray-700 font-inter"
									dangerouslySetInnerHTML={{
										__html: jobDetails?.jobDescription?.lookingToHire,
									}}
								/>
							</div>
							<div className="mb-8">
								<h2 className="text-base font-semibold mb-2 font-inter">Responsibilities</h2>
								<ul className="list-disc text-gray-700 font-inter pl-4">
									{jobDetails?.jobDescription?.responsibilities?.map((item, index) => {
										return (
											<li key={index}>
												<div
													dangerouslySetInnerHTML={{
														__html: item.value,
													}}
												/>
											</li>
										);
									})}
								</ul>
							</div>
							<div className="mb-8">
								<h2 className="text-base font-semibold mb-2 font-inter">Requirements</h2>
								<ul className="list-disc text-gray-700 font-inter pl-4">
									{jobDetails?.jobDescription?.qualifications?.map((item, index) => {
										return (
											<li key={index}>
												<div
													dangerouslySetInnerHTML={{
														__html: item.value,
													}}
												/>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
						<Card className="shadow-md overflow-hidden">
							<CardHeader className="bg-gray-50 border-b border-gray-200">
								<CardTitle className="text-base font-semibold font-plus-jakarta">
									About {jobDetails?.companyName}
								</CardTitle>
							</CardHeader>
							<CardContent className="p-6">
								<div className="flex items-center mb-4">
									<div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0 border border-gray-100 mr-4">
										<img
											alt={`${jobDetails?.companyName} logo`}
											loading="lazy"
											width="64"
											height="64"
											decoding="async"
											data-nimg="1"
											className="w-full h-full object-cover"
											style={{ color: "transparent" }}
											src={jobDetails?.company ? jobDetails?.company?.logo_url : "https://via.placeholder.com/150"}
										/>
									</div>
									<div>
										<p className="text-gray-700 mt-1 font-inter text-sm">
											Leading technology solutions provider in Tanzania, focused on building innovative solutions for
											the African market. Our mission is to transform industries through cutting-edge technology.
										</p>
									</div>
								</div>
								<div className="flex flex-wrap gap-2 text-sm text-gray-600 font-inter mb-4">
									<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200/50 text-gray-800">
										<BuildingIcon className="w-3 h-3 mr-1 text-gray-600" />
										{jobDetails?.company?.company_industry}
									</span>
									<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200/50 text-gray-800">
										<UsersIcon className="w-3 h-3 mr-1 text-gray-600" />
										{jobDetails?.company?.company_size_approx} Employees
									</span>
									<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200/50 text-gray-800">
										<MapPinIcon className="w-3 h-3 mr-1 text-gray-600" />
										{jobDetails?.jobLocation?.city}, {jobDetails?.jobLocation?.region}
									</span>
									<span
										className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-green-800"
										style={{ color: "#34663D", backgroundColor: "#E2FBE8" }}
									>
										<BriefcaseIcon className="w-3 h-3 mr-1 text-green-600" />
										Actively Hiring
									</span>
								</div>
								<div className="flex items-center">
									<GlobeIcon className="w-5 h-5 mr-2 text-gray-600" />
									<span className="font-semibold mr-1 text-sm">Website:</span>
									<a
										href={jobDetails?.company?.company_website}
										target="_blank"
										rel="noopener noreferrer"
										className="text-primary-700 hover:underline text-sm"
									>
										{jobDetails?.company?.company_website}
									</a>
								</div>
							</CardContent>
						</Card>
						<div className="mt-12">
							<h2 className="text-lg font-semibold mb-4 font-plus-jakarta">More Job Opportunities</h2>
							<div>
								{moreJobs
									.filter((item) => item?._id !== jobDetails?._id)
									.map((item, index) => {
										return (
											<JobListingItem
												key={index}
												job={{
													...item,
													logo: item.company.logo_url,
													company: item.companyName,
												}}
												onApply={() => {
													if (data) {
														setData(item);
													} else {
														navigate(`/jobs/${item?._id}`);
													}
												}}
												onClick={() => {
													/* Handle click action */
												}}
												showCompany={true}
												titleClassName="text-base"
												hideSalary={true}
											/>
										);
									})}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default ViewJob;
