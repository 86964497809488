import React from "react";

import { MapPinIcon } from "../../../assets/icons/interface/interface";
import { cn } from "../../../handlers/cn.utils";
import Loader from "../../StaticPages/HomePage/components/Loader";
import { Card } from "./Card";

function Locations({ loading, addresses, setAddressLocation, addressLocation }) {
	return (
		<Card className="p-4">
			<h3 className="text-sm font-medium text-gray-900 font-inter mb-3">Browse by Location</h3>
			{loading && (
				<div className="mt-5">
					<Loader />
				</div>
			)}
			{!loading && (
				<div className="space-y-1">
					{addresses.map((loc, index) => (
						<button
							key={index}
							className={cn(
								"ignore-focus-behavior w-full text-left px-3 py-2 rounded-md transition-colors duration-200 flex items-center justify-between group",
								addressLocation === loc.location
									? "bg-primary-50 text-primary-700 hover:bg-primary-100"
									: "text-gray-700 hover:bg-gray-50 hover:text-gray-900",
							)}
							onClick={() => setAddressLocation(loc.location)}
						>
							<div className="flex items-center">
								<MapPinIcon className="w-4 h-4 mr-2 opacity-75" />
								<span className="text-sm font-medium">{loc.location}</span>
							</div>
							<span className="text-xs text-gray-500 tabular-nums">{loc.count}</span>
						</button>
					))}
				</div>
			)}
		</Card>
	);
}

export default Locations;
