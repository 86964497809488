import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";

import { XIcon } from "../../../assets/icons/interface/interface";
import { cn } from "../../../handlers/cn.utils";
import { Button } from "../../JobsPage/components/Button";

export function Modal({
	isOpen,
	onClose,
	title,
	children,
	size = "small",
	primaryAction,
	secondaryAction,
	hideHeader = false,
	variant = "default",
	rightContent,
	triggerRect,
}) {
	const [, setIsAnimating] = useState(false);

	useEffect(() => {
		if (isOpen) {
			setIsAnimating(true);
			document.body.style.overflow = "hidden";
		} else {
			setIsAnimating(false);
			document.body.style.overflow = "";
		}
		return () => {
			document.body.style.overflow = "";
		};
	}, [isOpen]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "Escape") {
				onClose();
			}
		};

		if (isOpen) {
			document.addEventListener("keydown", handleKeyDown);
		} else {
			document.removeEventListener("keydown", handleKeyDown);
		}

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [isOpen, onClose]);

	const modalVariants = {
		hidden: (triggerRect) => ({
			opacity: 0,
			scale: 0.8,
			x: triggerRect ? triggerRect.left + triggerRect.width / 2 : 0,
			y: triggerRect ? triggerRect.top + triggerRect.height / 2 : 0,
		}),
		visible: {
			opacity: 1,
			scale: 1,
			x: 0,
			y: 0,
			transition: {
				type: "spring",
				stiffness: 300,
				damping: 30,
			},
		},
		exit: {
			opacity: 0,
			scale: 0.8,
			transition: {
				duration: 0.2,
			},
		},
	};

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className={cn("fixed inset-0 flex items-center justify-center z-50", "bg-black/30")}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
				>
					<motion.div
						className={cn(
							"bg-white rounded-lg shadow-md overflow-hidden",
							size === "small" && "w-full max-w-md",
							size === "medium" && "w-full max-w-2xl",
							size === "large" && "w-full max-w-4xl",
							variant === "split" && "flex",
						)}
						variants={modalVariants}
						initial="hidden"
						animate="visible"
						exit="exit"
						custom={triggerRect}
					>
						<div className={cn("flex flex-col", variant === "split" && "w-3/5")}>
							{!hideHeader && (
								<div className="p-5 pb-3">
									<div className="flex justify-between items-center mb-0">
										<h2 className="text-gray-900 font-medium text-base mb-0">{title}</h2>
										<Button
											variant="ghost"
											size="icon"
											onClick={onClose}
											className="text-gray-500 hover:text-gray-700 w-8 h-8 p-0 -mr-2 -mt-2 rounded-full"
										>
											<XIcon className="h-5 w-5" />
										</Button>
									</div>
								</div>
							)}
							<div className={cn("px-5 flex-grow", hideHeader ? "pt-5" : "pt-2")}>{children}</div>
							{(primaryAction || secondaryAction) && (
								<div className="px-6 py-4 border-t border-gray-200">
									<div className="flex justify-end gap-3">
										{secondaryAction && (
											<Button
												variant="outline"
												onClick={secondaryAction.onClick}
												className="h-[36px] bg-white text-gray-700 border border-gray-300 hover:bg-gray-50 py-2 text-[14px] font-normal font-inter"
											>
												{secondaryAction.label}
											</Button>
										)}
										{primaryAction && (
											<Button
												onClick={primaryAction.onClick}
												disabled={primaryAction.disabled}
												className="h-[36px] bg-primary-700 hover:bg-primary-800 text-white py-2 text-[14px] font-normal font-inter"
											>
												{primaryAction.label}
											</Button>
										)}
									</div>
								</div>
							)}
						</div>
						{variant === "split" && rightContent && (
							<div className="w-2/5 bg-gray-50 flex-shrink-0 flex-grow p-5 pt-0">
								<div className={cn(hideHeader ? "pt-5" : "pt-4")}>
									<div className="mt-0">{rightContent}</div>
								</div>
							</div>
						)}
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
}
