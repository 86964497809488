import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { useJobCategories } from "../../services/create-resume/queries";
import { ResumeDataService } from "../../services/create-resume/resume-data";
import Header from "../StaticPages/Header";
import Loader from "../StaticPages/HomePage/components/Loader";
import { Button } from "./components/Button";
import Categories from "./components/Categories";
import Jobs from "./components/Jobs";
import Locations from "./components/Locations";
import Search from "./components/Search";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/Select";

let persistJobsData = [];

function JobsPage() {
	const location = useLocation();
	const { searchedJobTitle, searchedAddress, jobSearchSource } = location.state || {};
	const apiService = new ResumeDataService();

	const [categories, setCategories] = React.useState([]);
	const [selectedCategory, setSelectedCategory] = React.useState("all");

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const category = urlParams.get("category");
		if (category) {
			setSelectedCategory(category);
		}
	}, [location.search]);

	const [jobs, setJobs] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	const [noNextPage, setNoNextPage] = React.useState(true);
	const [pageNumber, setPageNumber] = React.useState(1);
	const [totalPages, setTotalPages] = React.useState(1);
	const [addresses, setAddresses] = React.useState([]);
	const [jobTypes, setJobTypes] = React.useState([]);
	const [experienceLevels, setExperienceLevels] = React.useState([]);
	const [searchedKeyWord, setSearchKeyword] = React.useState(searchedJobTitle || "");
	const [addressLocation, setAddressLocation] = React.useState(searchedAddress || "");
	const [selectedJobTypes, setSelectedJobTypes] = React.useState([]);
	const [selectedExperienceLevels, setSelectedExperienceLevels] = React.useState([]);
	const [selectedSortingType, setSelectedSortingType] = React.useState("upcoming");

	const handleNext = () => {
		if (pageNumber >= totalPages) {
			return;
		}
		const nextPage = pageNumber + 1;
		setPageNumber(nextPage);
		fetchJobsOnSearch({ page: nextPage });
	};

	const handlePrevious = () => {
		if (pageNumber > 1) {
			let previousPage = pageNumber - 1;
			setPageNumber(previousPage);
			fetchJobsOnSearch({ page: 1 });
		}
	};

	const fetchJobsOnSearch = ({ page: page, categoryName: categoryName, location: location }) => {
		setLoading(true);
		const address = location?.split(",") || addressLocation.split(",");
		const searchQueryParams = {
			title: searchedKeyWord || "",
			country: "Tanzania",
			region: address?.[1] || "",
			city: address?.[0] || "",
			jobExpertise: [categoryName || selectedCategory].filter((category) => category.toLowerCase() !== "all"),
			pageNumber: page,
		};
		apiService.getSearchedJobs(searchQueryParams, successCallBack, errorCallBack);
	};

	const successCallBack = (response) => {
		let jobLocations = [];
		let locationCountMap = new Map();

		let employmentTypes = [];
		let employmentTypeCountMap = new Map();

		let jobExperienceLevels = [];
		let jobExperienceLevelCountMap = new Map();

		response?.data?.[0]?.paginatedJobs?.forEach((item) => {
			item.jobs.forEach((job) => {
				const location = `${job.jobLocation.city}, ${job.jobLocation.country}`;
				locationCountMap.set(location, (locationCountMap.get(location) || 0) + 1);
				employmentTypeCountMap.set(job.employmentType, (employmentTypeCountMap.get(job.employmentType) || 0) + 1);
				jobExperienceLevelCountMap.set(
					job.experienceQualification.minimumLevelOfExperience,
					(jobExperienceLevelCountMap.get(job.experienceQualification.minimumLevelOfExperience) || 0) + 1,
				);
			});
		});

		jobLocations = Array.from(locationCountMap, ([location, count]) => ({ location, count }));
		employmentTypes = Array.from(employmentTypeCountMap, ([name, count]) => ({ name, count }));
		jobExperienceLevels = Array.from(jobExperienceLevelCountMap, ([name, count]) => ({ name, count }));

		const newJobs = response?.data?.[0]?.paginatedJobs || [];
		sortJobs(newJobs, selectedSortingType);
		persistJobsData = newJobs;

		const pages = Math.ceil(response.data[0].totalJobs / response.data[0].totalJobsInPage);
		setTotalPages(pages === Infinity ? totalPages : pages);
		setAddresses(jobLocations);
		setJobTypes(employmentTypes);
		setExperienceLevels(jobExperienceLevels);

		//calculate next pagination
		const alreadyPaginatedJobs = pageNumber * response.data[0].pageSize;
		setNoNextPage(response.data[0].totalJobs - alreadyPaginatedJobs <= 0);

		setLoading(false);
	};

	const errorCallBack = () => {
		setLoading(false);
		toast.error("Something went wrong");
	};

	const { data: response, isLoading: loadingCategories } = useJobCategories();

	function filterJobs(jobs, filters) {
		return jobs.filter((job) => {
			return Object.keys(filters).every((key) => {
				// Skip if filter is not provided
				if (filters[key] === undefined || filters[key] === null) return true;

				// Handle nested fields
				if (key === "jobLocation") {
					return job.jobLocation.city.toLowerCase().includes(filters[key].toLowerCase());
				}

				if (key === "jobCategory") {
					return job.jobExpertise.toLowerCase().includes(filters[key].toLowerCase());
				}

				if (key === "jobTitle") {
					return job.jobTitle.toLowerCase().includes(filters[key].toLowerCase());
				}

				if (key === "employmentTypes") {
					return filters[key].some((type) => job.employmentType.toLowerCase().includes(type.toLowerCase()));
				}

				if (key === "experienceLevels") {
					return filters[key].some((level) =>
						job.experienceQualification.minimumLevelOfExperience.toLowerCase().includes(level.toLowerCase()),
					);
				}

				return false; // If the key doesn't match, return false
			});
		});
	}

	function sortJobs(jobsToBeSorted, sortingType) {
		const _jobs = [...jobsToBeSorted];

		// Function to get the closest closeAt date for a job
		function getCloseAtDate(job) {
			return new Date(job.closeAt);
		}

		// Function to get the lastUpdatedAt date for a job
		function getLastUpdatedAtDate(job) {
			return new Date(job.lastUpdatedAt);
		}

		// Sort companies based on their jobs' closeAt or lastUpdatedAt date
		_jobs.sort((a, b) => {
			// For "upcoming", we now just sort by the closest closeAt date in ascending order (oldest first, newest last)
			if (sortingType === "upcoming") {
				// Sort jobs within each company by closeAt date (ascending order)
				a.jobs.sort((job1, job2) => getCloseAtDate(job1) - getCloseAtDate(job2));
				b.jobs.sort((job1, job2) => getCloseAtDate(job1) - getCloseAtDate(job2));

				// Compare companies based on the earliest closeAt date from their sorted job list
				return getCloseAtDate(a.jobs[0]) - getCloseAtDate(b.jobs[0]);
			}

			// For "latest", we want the job with the most recent lastUpdatedAt
			if (sortingType === "latest") {
				// Find the job with the most recent lastUpdatedAt for each company
				const latestJobA = a.jobs.sort((job1, job2) => new Date(job2.lastUpdatedAt) - new Date(job1.lastUpdatedAt))[0]; // Sort by most recent lastUpdatedAt
				const latestJobB = b.jobs.sort((job1, job2) => new Date(job2.lastUpdatedAt) - new Date(job1.lastUpdatedAt))[0]; // Sort by most recent lastUpdatedAt

				// Sort based on the most recent lastUpdatedAt
				return getLastUpdatedAtDate(latestJobB) - getLastUpdatedAtDate(latestJobA); // Descending order
			}

			return 0; // Default case (if order is invalid)
		});

		setJobs(_jobs);
	}

	React.useEffect(() => {
		if (selectedCategory !== "all" || (selectedCategory === "all" && searchedKeyWord === "")) {
			const filters = {
				jobTitle: searchedKeyWord === "" ? null : searchedKeyWord,
				jobCategory: selectedCategory === "" ? null : selectedCategory === "all" ? null : selectedCategory,
				jobLocation: addressLocation.split(",")?.[0] === "" ? null : addressLocation.split(",")?.[0],
				employmentTypes: selectedJobTypes.length === 0 ? null : selectedJobTypes,
				experienceLevels: selectedExperienceLevels.length === 0 ? null : selectedExperienceLevels,
			};

			const updatedJobs = persistJobsData
				.map((item) => {
					const companyJobs = filterJobs(item.jobs, filters);

					if (companyJobs.length === 0) {
						return;
					}

					return { jobs: companyJobs, companyDetails: item.companyDetails };
				})
				.filter(Boolean);

			sortJobs(updatedJobs, selectedSortingType);
		}
	}, [addressLocation, selectedCategory, selectedJobTypes, searchedKeyWord, selectedExperienceLevels]);

	React.useEffect(() => {
		fetchJobsOnSearch({ page: 1 });
	}, []);

	React.useEffect(() => {
		if (response && !loadingCategories) {
			setCategories(response.data);
		}
	}, [response, loadingCategories]);

	return (
		<div className="bg-gray-50 min-h-screen">
			<Header activeLink="jobs" />

			<Search
				setLoading={setLoading}
				searchedAddress={searchedAddress}
				jobSearchSource={jobSearchSource}
				selectedCategory={selectedCategory}
				searchedKeyWord={searchedKeyWord}
				setSearchedKeyWord={setSearchKeyword}
				setSelectedCategory={setSelectedCategory}
				fetchJobsOnSearch={fetchJobsOnSearch}
				jobTypes={jobTypes}
				experienceLevels={experienceLevels}
				setAddressLocation={setAddressLocation}
				addressLocation={addressLocation}
				selectedJobTypes={selectedJobTypes}
				setSelectedJobTypes={setSelectedJobTypes}
				selectedExperienceLevels={selectedExperienceLevels}
				setSelectedExperienceLevels={setSelectedExperienceLevels}
			/>

			<div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
				<div className="flex flex-col lg:flex-row gap-8">
					<div className="lg:w-3/4">
						{loading && (
							<div className="mt-5">
								<Loader />
							</div>
						)}
						{!loading && (
							<>
								{/* Title and description with specific height */}
								<div className="h-[88px]">
									{" "}
									{/* Added fixed height container */}
									<h2 className="text-3xl font-bold mb-2 text-gray-900 font-plus-jakarta">
										{selectedCategory !== "all"
											? `${selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)} Jobs${addressLocation ? ` in ${addressLocation}` : ""}`
											: `All Jobs${addressLocation ? ` in ${addressLocation}` : ""}`}
									</h2>
									<div className="flex items-center justify-between">
										<p className="text-sm text-gray-700 font-inter">
											Showing {jobs.length} companies with job openings
										</p>
										<div>
											<Select
												value={selectedSortingType}
												onValueChange={(value) => {
													setSelectedSortingType(value);
													sortJobs(jobs, value);
												}}
											>
												<SelectTrigger unBordered>
													<SelectValue placeholder="Select category" />
												</SelectTrigger>
												<SelectContent>
													<SelectItem value="upcoming"> Ordered by upcoming deadline </SelectItem>
													<SelectItem value="latest"> Ordered by latest jobs </SelectItem>
												</SelectContent>
											</Select>
										</div>
									</div>
								</div>

								<Jobs jobs={jobs} />

								<div className="flex justify-center mt-8 space-x-2">
									<Button
										className="ignore-focus-behavior"
										variant="outline"
										onClick={handlePrevious}
										disabled={pageNumber === 1}
									>
										Previous
									</Button>
									<Button
										className="ignore-focus-behavior"
										variant="outline"
										onClick={handleNext}
										disabled={noNextPage}
									>
										Next
									</Button>
								</div>
							</>
						)}
					</div>
					<div className="lg:w-1/4 space-y-6 lg:mt-[88px]">
						<Categories
							categories={categories}
							setSelectedCategory={setSelectedCategory}
							selectedCategory={selectedCategory}
							loadingCategories={loadingCategories}
							fetchJobsOnSearch={fetchJobsOnSearch}
						/>
						<Locations
							addresses={addresses}
							setAddressLocation={setAddressLocation}
							addressLocation={addressLocation}
							fetchJobsOnSearch={fetchJobsOnSearch}
							loading={loading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default JobsPage;
