import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Drawer from "../../../../components/Drawer/Drawer";
import { cn } from "../../../../handlers/cn.utils";
import { useUpcomingJobs } from "../../../../services/create-resume/queries";
import JobListingItem from "../../../JobsPage/components/JobListingItem";
import ViewJob from "./ViewJob";

function JobsByCategory() {
	const [showJobDetailsModal, setShowJobDetailsModal] = React.useState(false);
	const [selectedJob, setSelectedJob] = React.useState(null);

	const navigate = useNavigate();
	const [categories, setCategories] = React.useState([]);
	const [selectedCategory, setSelectedCategory] = React.useState("All");
	const [jobs, setJobs] = React.useState([]);

	const { data: response, isLoading } = useUpcomingJobs();

	React.useEffect(() => {
		if (!isLoading && response) {
			setJobs(response.data);
		}
	}, [isLoading, response]);

	useEffect(() => {
		const categories = jobs.map((job) => job.jobExpertise);
		const uniqueCategories = [...new Set(categories)];
		setCategories(uniqueCategories);
	}, [jobs]);

	const [filteredJobs, setFilteredJobs] = React.useState([...jobs]);

	React.useEffect(() => {
		if (selectedCategory === "All") {
			setFilteredJobs(jobs);
		} else {
			const filtered = jobs.filter((job) => job.jobExpertise === selectedCategory);

			setFilteredJobs(filtered);
		}
	}, [selectedCategory, jobs]);

	const filtersContainerRef = React.useRef(null);

	function handleScroll(direction) {
		const container = filtersContainerRef.current;
		const scrollAmount = 200;

		if (direction === "right") {
			container.scrollLeft += scrollAmount;
		} else {
			container.scrollLeft -= scrollAmount;
		}
	}

	return (
		<section className="py-12 relative overflow-hidden">
			<Drawer
				isOpen={showJobDetailsModal}
				onClose={() => {
					setShowJobDetailsModal(false);
				}}
			>
				<ViewJob data={selectedJob} setData={setSelectedJob} />
			</Drawer>
			<div className="relative z-10 max-w-6xl mx-auto">
				<h2 className="!text-4xl font-bold mb-8 text-gray-900 font-plus-jakarta">Job Listings by Category</h2>
				<div className="relative mb-8 overflow-hidden">
					<button
						className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-lg p-2 shadow-md z-10"
						onClick={() => handleScroll("right")}
					>
						<span className="text-2xl">›</span>
					</button>
					<div className="flex overflow-x-hidden scrollbar-hide !pb-4" ref={filtersContainerRef}>
						<div className="flex w-full gap-3">
							<span
								className={cn(
									"flex-1 px-6 py-3 rounded text-[14px] min-w-fit cursor-pointer text-center font-medium transition-all duration-300 transform hover:scale-105 hover:shadow-md bg-[#fff] text-gray-700 !shadow",
									{
										"!bg-gray-900 text-[white]": selectedCategory === "All",
									},
								)}
								onClick={() => setSelectedCategory("All")}
							>
								All
							</span>
							{categories.map((category, index) => (
								<span
									className={cn(
										"flex-1 px-6 py-3 min-w-fit cursor-pointer rounded text-[14px] text-center font-medium transition-all duration-300 transform hover:scale-105 hover:shadow-md bg-[#fff] text-gray-700 ml-2 !shadow",
										{
											"!bg-gray-700 text-[white] focus:text-[white]": selectedCategory === category,
										},
									)}
									onClick={() => setSelectedCategory(category)}
									key={index}
								>
									{category}
								</span>
							))}
						</div>
					</div>
				</div>
				<div className="relative overflow-hidden rounded-lg">
					<div className="absolute inset-0 bg-[linear-gradient(60deg,_#ffffff_0%,_var(--tw-color-primary-50)_50%,_#ffffff_100%)] opacity-50 backdrop-filter backdrop-blur-sm" />
					<div className="relative z-10">
						{filteredJobs.map((job, index) => (
							<JobListingItem
								key={index}
								job={{
									...job,
									company: job.companyName,
									logo: job.company ? job.company.logo_url : "https://via.placeholder.com/150",
									salary: null,
								}}
								showCompany={true}
								onClick={() => {
									setSelectedJob(job);
									setShowJobDetailsModal(true);
								}}
								onApply={() => {
									setSelectedJob(job);
									setShowJobDetailsModal(true);
								}}
								titleClassName="text-sm font-semibold"
							/>
						))}
					</div>
				</div>
				<div className="flex justify-between items-center py-4 border-b border-gray-200 transition-colors duration-300">
					<div className="mt-8 mx-auto">
						<button
							className="inline-block bg-[#fff] border border-gray-200 text-gray-700 px-6 py-2 rounded hover:bg-gray-50 transition-colors font-inter"
							onClick={() => {
								navigate("/jobs", {
									state: {
										searchedAddress: "",
										searchedJobTitle: "",
										jobSearchSource: "upcoming",
									},
								});
							}}
						>
							View {selectedCategory === "All" ? "" : "All " + selectedCategory} Jobs
						</button>
						{/*<a*/}
						{/*	className="inline-block bg-[#fff] border border-gray-200 text-gray-700 px-6 py-2 rounded hover:bg-gray-50 transition-colors font-inter"*/}
						{/*	href={`/jobs?category=${selectedCategory}`}*/}
						{/*>*/}
						{/*	View {selectedCategory === "All" ? "" : "All " + selectedCategory} Jobs*/}
						{/*</a>*/}
					</div>
				</div>
			</div>
		</section>
	);
}

export default JobsByCategory;
