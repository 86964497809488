import axios from "axios";

import { StaticValue } from "../seed/constants";

const staticValue = new StaticValue();
const staticValues = staticValue.getDeafaulValues();

export const API = axios.create({
	baseURL: staticValues.apiUrl,
	headers: {
		"Content-Type": "application/json",
		...staticValues.commonHeader,
	},
});
