import React from "react";

function Loader() {
	return (
		<div className="flex items-center justify-center">
			<div className="w-20 h-20 border-4 border-blue-500 border-dashed rounded-full animate-spin p-4" />
		</div>
	);
}

export default Loader;
