import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useJobCategories } from "../../../../services/create-resume/queries";
import Categories from "./Categories";
import LocationSearchDropdown from "./LocationSearchDropdown";

function OverTheFold() {
	const navigate = useNavigate();
	const [categories, setCategories] = React.useState([]);
	const [jobTitle, setJobTitle] = React.useState("");
	const [addressLocation, setAddressLocation] = React.useState("");

	const { data: response, isLoading } = useJobCategories();

	useEffect(() => {
		if (response && !isLoading) {
			setCategories(response.data);
		}
	}, [response, isLoading]);

	return (
		<main className="flex-grow">
			<section className="relative py-20 px-4 md:px-8 overflow-hidden">
				<div className="absolute inset-0 z-0 top-[-40px]">
					<div className="opacity-50">
						<svg viewBox="0 0 3000 1200" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
							<mask id="b" x="0" y="0" width="3000" height="1200">
								<path fill="url(#a)" d="M0 0h3000v1200H0z" />
							</mask>
							<path fill="#fff" d="M0 0h3000v1200H0z" />
							<g style={{ transformOrigin: "center center" }} stroke="#dbdbe2" strokeWidth="2" mask="url(#b)">
								<path
									fill="none"
									d="M0 0h181.818v181.818H0zM181.818 0h181.818v181.818H181.818zM363.636 0h181.818v181.818H363.636zM545.455 0h181.818v181.818H545.455zM727.273 0h181.818v181.818H727.273zM909.091 0h181.818v181.818H909.091zM1090.909 0h181.818v181.818h-181.818zM1272.727 0h181.818v181.818h-181.818zM1636.364 0h181.818v181.818h-181.818z"
								/>
								<path fill="#dbdbe257" d="M1818.182 0H2000v181.818h-181.818z" />
								<path
									fill="none"
									d="M0 181.818h181.818v181.818H0zM181.818 181.818h181.818v181.818H181.818zM363.636 181.818h181.818v181.818H363.636zM545.455 181.818h181.818v181.818H545.455zM727.273 181.818h181.818v181.818H727.273z"
								/>
								<path fill="#dbdbe251" d="M909.091 181.818h181.818v181.818H909.091z" />
								<path fill="none" d="M1090.909 181.818h181.818v181.818h-181.818z" />
								<path fill="#dbdbe26b" d="M1272.727 181.818h181.818v181.818h-181.818z" />
								<path
									fill="none"
									d="M1454.545 181.818h181.818v181.818h-181.818zM1636.364 181.818h181.818v181.818h-181.818zM1818.182 181.818H2000v181.818h-181.818zM0 363.636h181.818v181.818H0zM545.455 363.636h181.818v181.818H545.455z"
								/>
								<path fill="#dbdbe294" d="M727.273 363.636h181.818v181.818H727.273z" />
								<path fill="#dbdbe23b" d="M909.091 363.636h181.818v181.818H909.091z" />
								<path fill="#dbdbe274" d="M1090.909 363.636h181.818v181.818h-181.818z" />
								<path
									fill="none"
									d="M1454.545 363.636h181.818v181.818h-181.818zM1636.364 363.636h181.818v181.818h-181.818z"
								/>
								<path fill="#dbdbe2c1" d="M1818.182 363.636H2000v181.818h-181.818z" />
								<path fill="#dbdbe2ce" d="M0 545.455h181.818v181.818H0z" />
								<path fill="#dbdbe2f4" d="M181.818 545.455h181.818v181.818H181.818z" />
								<path fill="#dbdbe2d0" d="M363.636 545.455h181.818v181.818H363.636z" />
								<path
									fill="none"
									d="M909.091 545.455h181.818v181.818H909.091zM1090.909 545.455h181.818v181.818h-181.818zM1454.545 545.455h181.818v181.818h-181.818z"
								/>
								<path fill="#dbdbe23e" d="M1636.364 545.455h181.818v181.818h-181.818z" />
								<path
									fill="none"
									d="M1818.182 545.455H2000v181.818h-181.818zM0 727.273h181.818v181.818H0zM181.818 727.273h181.818v181.818H181.818zM363.636 727.273h181.818v181.818H363.636z"
								/>
								<path fill="#dbdbe28c" d="M545.455 727.273h181.818v181.818H545.455z" />
								<path fill="#dbdbe238" d="M727.273 727.273h181.818v181.818H727.273z" />
								<path fill="none" d="M909.091 727.273h181.818v181.818H909.091z" />
								<path fill="#dbdbe2c6" d="M1090.909 727.273h181.818v181.818h-181.818z" />
								<path fill="#dbdbe228" d="M1272.727 727.273h181.818v181.818h-181.818z" />
								<path
									fill="none"
									d="M1454.545 727.273h181.818v181.818h-181.818zM1636.364 727.273h181.818v181.818h-181.818z"
								/>
								<path fill="#dbdbe2b9" d="M1818.182 727.273H2000v181.818h-181.818z" />
								<path fill="none" d="M0 909.091h181.818v181.818H0z" />
								<path fill="#dbdbe245" d="M181.818 909.091h181.818v181.818H181.818z" />
								<path fill="#dbdbe283" d="M363.636 909.091h181.818v181.818H363.636z" />
								<path
									fill="none"
									d="M545.455 909.091h181.818v181.818H545.455zM727.273 909.091h181.818v181.818H727.273z"
								/>
								<path fill="#dbdbe2f6" d="M1454.545 909.091h181.818v181.818h-181.818z" />
								<path
									fill="none"
									d="M1636.364 909.091h181.818v181.818h-181.818zM1818.182 909.091H2000v181.818h-181.818zM0 1090.909h181.818v181.818H0z"
								/>
								<path fill="#dbdbe2d1" d="M181.818 1090.909h181.818v181.818H181.818z" />
								<path fill="#dbdbe248" d="M363.636 1090.909h181.818v181.818H363.636z" />
								<path
									fill="none"
									d="M545.455 1090.909h181.818v181.818H545.455zM909.091 1090.909h181.818v181.818H909.091z"
								/>
								<path fill="#dbdbe2ea" d="M1090.909 1090.909h181.818v181.818h-181.818z" />
								<path
									fill="none"
									d="M1454.545 1090.909h181.818v181.818h-181.818zM1636.364 1090.909h181.818v181.818h-181.818zM1818.182 1090.909H2000v181.818h-181.818z"
								/>
								<path fill="#dbdbe229" d="M0 1272.727h181.818v181.818H0z" />
								<path fill="none" d="M363.636 1272.727h181.818v181.818H363.636z" />
								<path fill="#dbdbe29f" d="M545.455 1272.727h181.818v181.818H545.455z" />
								<path fill="#dbdbe27d" d="M727.273 1272.727h181.818v181.818H727.273z" />
								<path fill="none" d="M1090.909 1272.727h181.818v181.818h-181.818z" />
								<path fill="#dbdbe2b3" d="M1272.727 1272.727h181.818v181.818h-181.818z" />
								<path
									fill="none"
									d="M1454.545 1272.727h181.818v181.818h-181.818zM1636.364 1272.727h181.818v181.818h-181.818zM1818.182 1272.727H2000v181.818h-181.818z"
								/>
							</g>
							<defs>
								<radialGradient id="a">
									<stop offset="0" stopColor="#fff" />
									<stop offset="1" stopColor="#fff" stopOpacity="0" />
								</radialGradient>
							</defs>
						</svg>
					</div>
				</div>
				<div className="max-w-6xl mx-auto relative z-10">
					<h1 className="!text-6xl !md:text-6xl font-bold mb-6 text-gray-900 font-plus-jakarta leading-tight text-center">
						Discover Your Perfect Career Opportunity
					</h1>
					<p className="!text-2xl mb-10 text-gray-700 font-inter text-center">
						Connect with top employers and find the job that matches your skills and aspirations.
					</p>
					<div className="relative mb-8" style={{ opacity: 1, transform: "none" }}>
						<div
							className="absolute inset-0 blur-3xl"
							style={{
								background:
									"radial-gradient(circle at 15.705516906503458% 96.60258999546971%, var(--color-primary-200) 0%, var(--color-primary-300) 25%, var(--color-primary-400) 50%, transparent 80%)",
								opacity: 0.4,
							}}
						/>
						<div
							className="backdrop-blur-sm p-6 rounded-lg relative z-20 overflow-visible"
							style={{ boxShadow: "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)" }}
						>
							<div
								className="absolute inset-0 z-0 !rounded-lg"
								style={{
									background: "linear-gradient(to top, var(--color-primary-500) 0%, transparent 150%)",
									opacity: 0.5,
									mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
									maskComposite: "exclude",
									WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
									WebkitMaskComposite: "xor",
									padding: "1px",
									borderRadius: "0.5rem",
									overflow: "hidden",
								}}
							>
								<div className="shiny-dot" />
							</div>
							<div className="flex flex-col md:flex-row gap-4 relative z-10 items-center">
								<div className="flex-grow">
									<div className="relative flex-grow">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="1.5"
											stroke="currentColor"
											className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-300 w-5 h-5"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
											/>
										</svg>
										<input
											value={jobTitle}
											type="text"
											className="flex w-full border border-input px-3 py-2 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 flex-grow h-12 text-base !rounded-md !pl-12 placeholder-gray-600 bg-white focus:ring-2 focus:ring-primary-600 focus:border-transparent font-semibold placeholder:font-semibold"
											placeholder="Job title or keyword"
											onChange={(e) => setJobTitle(e.target.value)}
										/>
									</div>
								</div>
								<LocationSearchDropdown addressLocation={addressLocation} setAddressLocation={setAddressLocation} />
								<div>
									<button
										className="inline-flex items-center justify-center transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none ring-offset-background py-2 h-12 px-8 text-base font-medium bg-primary-700 hover:bg-primary-800 text-white rounded-lg"
										disabled={!(jobTitle !== "" || addressLocation !== "")}
										onClick={() => {
											navigate("/jobs", {
												state: {
													searchedAddress: addressLocation,
													searchedJobTitle: jobTitle,
												},
											});
										}}
									>
										Search
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-8">
						{isLoading ? (
							<div className="grid grid-cols-2 animate-pulse md:grid-cols-4 gap-4">
								{[...Array(8).fill(0)].map((_, index) => (
									<div className="h-28 w-full bg-gray-900 opacity-10 rounded-md" key={index} />
								))}
							</div>
						) : (
							<Categories categories={categories} />
						)}
					</div>
				</div>
			</section>
		</main>
	);
}

export default OverTheFold;
