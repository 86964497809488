import React from "react";
import { Link } from "react-router-dom";

import Header from "../Header";
import LabeledList from "./components/LabeledList";

function ResumeTips() {
	return (
		<>
			<Header activeLink="tips" />
			<div className="bg-primary-200 mt-12 relative">
				<div className="mx-auto max-w-3xl pt-24 pb-56 tips-header-bg flex flex-col items-center justify-center">
					<h1 className="text-5xl font-semibold text-center max-w-[544px] mb-2">How to craft a winning resume</h1>
					<p className="text-primary-950 text-center text-2xl mb-5">
						Master the art of resume writing with our expert guide. Stand out, showcase your value, and boost your
						career prospects quickly.
					</p>
					<Link
						to="/resume/select-option"
						className="rounded-sm hover:bg-primary-200 bg-[white] font-semibold text-primary-950 hover:text-primary-900 btn-lg text-base px-10 !py-3"
					>
						Build your resume
					</Link>
					<img src="/images/tips-header.png" className="bottom-0 absolute max-w-3xl mix-blend-luminosity" alt="" />
				</div>
			</div>
			{/* layout and visual appeal */}
			<div className="container md:px-0 px-3 my-20">
				<h2 className="text-title-xl text-gray-960">Layout and Visual Appeal</h2>
				<p className="font-semibold text-base mb-6">
					The overall design and structure of your resume that creates the first impression.{" "}
					<a href="/resume/select-option" className="text-primary-600">
						Try them now!
					</a>
				</p>

				<div className="grid grid-cols-10 gap-8">
					<div className="col-span-10 md:col-span-6">
						<LabeledList
							type="do"
							content={[
								"Use a clean, professional layout with consistent formatting throughout the document.",
								"Utilize white space effectively to enhance readability and visual appeal.",
								"Choose a readable font (e.g., Arial, Calibri) in a size between 10-12 points.",
							]}
						/>
						<LabeledList
							type="dont"
							content={[
								"Create a cluttered layout with dense blocks of text and little white space.",
								"Use multiple fonts, colors, or excessive formatting that can distract from the content.",
								"Overcrowd the page, leaving no margins for readability.",
							]}
						/>

						<div className="">
							<p className="text-md font-semibold text-gray-950">Why does it matter?</p>
							<p className="text-md text-gray-800">
								A well-designed resume catches the eye and makes it easy for recruiters to find key information quickly,
								increasing your chances of making a positive first impression.
							</p>
						</div>
					</div>
					<div className="col-span-10 md:col-span-4">
						<img src="/images/resume-layout-appeal.png" alt="" className="mx-auto" />
					</div>
				</div>
			</div>
			{/* professional summary */}
			<div className="container my-20 md:px-0 px-3">
				<h2 className="text-title-xl text-gray-960">Professional Summary</h2>
				<p className="font-semibold text-base mb-6">
					Capture attention quickly by highlighting your key qualifications and career objectives.
				</p>

				<div className="grid grid-cols-10 gap-8">
					<div className="col-span-10 md:col-span-4">
						<p className="text-md font-semibold text-gray-950">Impact on your application</p>
						<p className="text-md text-gray-800">
							A compelling summary quickly communicates your value proposition, encouraging recruiters to read the rest
							of your resume and consider you for the position.
						</p>
					</div>
					<div className="col-span-10 md:col-span-6">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="col-span-1">
								<img src="/images/resume-professional-summary-do.png" className="!mb-5 mx-auto" alt="" />
								<LabeledList
									type="do"
									content={[
										"Craft a clear, concise summary highlighting key qualifications and career objectives.",
										"Tailor the summary to match the specific job requirements and company culture.",
										"Use strong action verbs and industry-relevant keywords to grab attention.",
									]}
								/>
							</div>
							<div className="col-span-1">
								<img src="/images/resume-professional-summary-dont.png" className="!mb-5 mx-auto" alt="" />

								<LabeledList
									type="dont"
									content={[
										"Write a vague or generic summary that could apply to any job seeker in your field.",
										"Make it too long or wordy, causing the reader to lose interest quickly.",
										"Include personal information or irrelevant details that don't directly relate to the job.",
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* work experience */}
			<div className="container my-20 md:px-0 px-3">
				<h2 className="text-title-xl text-gray-960">Work Experience</h2>
				<p className="font-semibold text-base mb-6">
					Showcase your professional journey that includes job titles, companies, dates, and key responsibilities and
					achievements.
				</p>

				<div className="grid grid-cols-10 gap-8">
					<div className="col-span-10 md:col-span-4">
						<p className="text-md font-semibold text-gray-950">Best practices</p>
						<p className="text-md text-gray-800 mb-6">
							Using action-oriented language in your resume is crucial for demonstrating your proactive approach and the
							tangible impact you&#39;ve made in your roles. Start each bullet point with a strong action verb that
							highlights your role and accomplishments. Examples include <strong>&#39;managed,&#39;</strong>
							<strong>&#39;led,&#39;</strong> <strong>&#39;developed,&#39;</strong>
							<strong>&#39;implemented,&#39;</strong> <strong>&#39;achieved,&#39;</strong>
							<strong>&#39;increased,&#39;</strong> <strong>&#39;solved,&#39;</strong> and
							<strong>&#39;contributed.&#39;</strong>
						</p>
						<p className="text-md font-semibold text-gray-950">Why it matters?</p>
						<p className="text-md text-gray-800">
							This approach not only makes your resume more dynamic and engaging but also helps to quantify your
							achievements. Instead of simply listing responsibilities, focus on results and use phrases like
							<strong>&#39;resulting in,&#39;</strong> <strong>&#39;which led to,&#39;</strong> or
							<strong>&#39;contributing to&#39;</strong> to showcase the outcomes of your actions. This method provides
							concrete evidence of your skills and value to potential employers.
						</p>
					</div>
					<div className="col-span-10 md:col-span-6">
						<img src="/images/resume-work-experience.png" alt="" />
					</div>
				</div>
			</div>
			{/* cta 1 */}
			<div className="container my-20 md:px-0 px-3">
				<div className="bg-primary-100 rounded-xl px-6 py-7 flex flex-col md:flex-row items-center w-full justify-between">
					<div className="flex flex-col md:flex-row items-center gap-4">
						<img src="/images/resume-cta-1.png" alt="" />
						<div className="">
							<h3 className="text-title-xl text-gray-950 mb-2">
								Build your resume effortlessly <br />
								with Dproz
							</h3>
							<p className="text-md text-gray-950">
								Use Dproz&#39;s easy-to-use tool to craft a visually appealing resume.
							</p>
						</div>
					</div>
					<Link
						to="/resume/select-experience"
						className="bg-primary-600 text-md text-white font-medium !py-3 px-10 my-3 md:my-0 rounded-sm"
					>
						Start Building
					</Link>
				</div>
			</div>
			{/* skills */}
			<div className="container my-20 md:px-0 px-3">
				<h2 className="text-title-xl text-gray-960">Skills</h2>
				<p className="font-semibold text-base mb-6">
					A concise list of your key technical and soft skills relevant to the job you&#39;re applying for.
				</p>

				<div className="grid grid-cols-10 gap-8">
					<div className="col-span-10 md:col-span-4">
						<p className="text-md font-semibold text-gray-950">Why is it important?</p>
						<p className="text-md text-gray-800 mb-6">
							A well-crafted skills section allows recruiters to quickly match your capabilities with job requirements,
							increasing your chances of passing initial screenings and applicant tracking systems (ATS).
						</p>
					</div>
					<div className="col-span-10 md:col-span-6">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="col-span-1">
								<img src="/images/resume-professional-summary-do.png" className="!mb-5 mx-auto" alt="" />
								<LabeledList
									type="do"
									content={[
										"List skills directly relevant to the job, prioritizing those mentioned in the job description.",
										"Include a mix of hard skills (technical abilities) and soft skills (interpersonal traits).",
										"Keep the list concise and well-organized, using bullet points or columns for easy scanning.",
									]}
								/>
							</div>
							<div className="col-span-1">
								<img src="/images/resume-professional-summary-dont.png" className="!mb-5 mx-auto" alt="" />

								<LabeledList
									type="dont"
									content={[
										"List generic or obvious skills that don't add value (e.g., 'Microsoft Word' for a senior professional).",
										"Overstate your abilities or include skills you're not comfortable using in a professional setting.",
										"Create a long, unorganized list that makes it difficult for recruiters to find key skills quickly.",
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* education */}
			<div className="container my-20 md:px-0 px-3">
				<h2 className="text-title-xl text-gray-960">Education Summary</h2>
				<p className="font-semibold text-base mb-6">
					Information about your academic credentials, including degrees, institutions, graduation dates, and relevant
					coursework etc.
				</p>

				<div className="grid grid-cols-10 gap-8">
					<div className="col-span-10 md:col-span-4">
						<p className="text-md font-semibold text-gray-950">Why employers value this?</p>
						<p className="text-md text-gray-800 mb-6">
							Your educational background validates your qualifications and demonstrates your commitment to learning and
							self-improvement, which is valuable in any professional setting.
						</p>
					</div>
					<div className="col-span-10 md:col-span-6">
						<img src="/images/resume-education.png" className="!mb-5 mx-auto" alt="" />
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="col-span-1">
								<LabeledList
									type="do"
									content={[
										"List degrees in reverse chronological order, including institution name, degree, and graduation year.",
										"Include relevant coursework, academic honors, or projects that relate to the job you're seeking.",
										"Mention continuing education or professional development courses relevant to your career goals.",
									]}
								/>
							</div>
							<div className="col-span-1">
								<LabeledList
									type="dont"
									content={[
										"Include high school information if you have a college degree (unless you're a recent graduate).",
										"List an incomplete degree without explaining your current status or expected completion date.",
										"Overemphasize education at the expense of work experience if you're not a recent graduate.",
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* achievements */}
			<div className="container my-20 md:px-0 px-3">
				<h2 className="text-title-xl text-gray-960">Achievements and Awards</h2>
				<p className="font-semibold text-base mb-6">
					Highlights of your professional accomplishments, and notable contributions in your field.
				</p>

				<div className="grid grid-cols-10 gap-8">
					<div className="col-span-10 md:col-span-4">
						<p className="text-md font-semibold text-gray-950">Why employers value this?</p>
						<p className="text-md text-gray-800 mb-6">
							Highlighting relevant achievements demonstrates your competence, sets you apart from other candidates, and
							provides concrete evidence of your potential value to employers.
						</p>
					</div>
					<div className="col-span-10 md:col-span-6">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="col-span-1">
								<img src="/images/resume-professional-summary-do.png" className="!mb-5 mx-auto" alt="" />
								<LabeledList
									type="do"
									content={[
										"Highlight relevant awards, recognitions, or certifications that showcase your expertise.",
										"Quantify achievements where possible to provide context (e.g., 'Top 5% of sales team').",
										"Include recent accomplishments that are relevant to your target role or industry.",
									]}
								/>
							</div>
							<div className="col-span-1">
								<img src="/images/resume-professional-summary-dont.png" className="!mb-5 mx-auto" alt="" />

								<LabeledList
									type="dont"
									content={[
										"List every award you've ever received, including irrelevant or outdated ones.",
										"Exaggerate or misrepresent your achievements.",
										"Include high school achievements unless you're a recent graduate with limited work experience.",
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* cta 2 */}
			<div className="container my-20 md:px-0 px-3">
				<div className="bg-primary-100 rounded-xl px-6 flex flex-col md:flex-row md:py-0 py-3 items-center w-full justify-between">
					<div className="flex flex-col md:flex-row items-center gap-4">
						<div className="">
							<h3 className="text-title-xl text-gray-950 mb-2">
								Highlight your skills and <br /> achievements for maximum impact
							</h3>
							<p className="text-md text-gray-950">
								Use Dproz’s smart suggestions tools to highlight your skills and accomplishments.
							</p>
						</div>
						<img src="/images/resume-cta-2.png" alt="" />
					</div>
					<Link
						to="/resume/select-option"
						className="bg-primary-600 text-md text-white font-medium !py-3 px-10 rounded-sm"
					>
						Get Started
					</Link>
				</div>
			</div>
			{/* personal info */}
			<div className="container my-20 md:px-0 px-3">
				<h2 className="text-title-xl text-gray-960">Personal Information</h2>
				<p className="font-semibold text-base mb-6">
					Add essential contact details and only professional online presence information.
				</p>

				<div className="grid grid-cols-10 gap-8">
					<div className="col-span-10 md:col-span-4">
						{/* <p className="text-md font-semibold text-gray-950">Why employers value this?</p>
						<p className="text-md text-gray-800 mb-6">
							Highlighting relevant achievements demonstrates your competence, sets you apart from other candidates, and
							provides concrete evidence of your potential value to employers.
						</p> */}
					</div>
					<div className="col-span-10 md:col-span-6">
						<img src="/images/resume-personal-info.png" className="!mb-5 mx-auto" alt="" />
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="col-span-1">
								<LabeledList
									type="do"
									content={[
										"Include essential contact information: full name, city/state, phone number, and professional email.",
										"Add links to professional online profiles or portfolios if relevant to the job.",
										"Ensure all personal information is current and professional.",
									]}
								/>
							</div>
							<div className="col-span-1">
								<LabeledList
									type="dont"
									variant="number"
									content={[
										"Include sensitive personal details like age, marital status, or social security number.",
										"Use an unprofessional email address or one associated with your current employer.",
										"Include a photo unless it's standard in your industry or requested by the employer.",
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* references */}
			<div className="container my-20 md:px-0 px-3">
				<h2 className="text-title-xl text-gray-960">References</h2>
				<p className="font-semibold text-base mb-6">Handle references professionally.</p>

				<div className="grid grid-cols-10 gap-8">
					<div className="col-span-10 md:col-span-6">
						<LabeledList
							type="do"
							content={[
								"Prepare a separate list of references to provide when requested.",
								"Inform your references that they may be contacted and provide them with your resume.",
								"Choose references who can speak positively about your work and character.",
							]}
						/>
						<LabeledList
							type="dont"
							content={[
								"Include references or the phrase 'References available upon request' on your resume.",
								"List reference contact information without their permission.",
								"Choose references who are not familiar with your recent work or professional abilities.",
							]}
						/>

						<div className="">
							<p className="text-md font-semibold text-gray-950">Why does it matter?</p>
							<p className="text-md text-gray-800">
								Proper handling of references maintains professionalism, respects your contacts&#39; privacy, and
								ensures you&#39;re prepared to provide strong endorsements when requested during the hiring process.
							</p>
						</div>
					</div>
					<div className="col-span-10 md:col-span-4">
						<img src="/images/resume-references.png" alt="" />
					</div>
				</div>
			</div>
			{/* customization */}
			<div className="container my-20 md:px-0 px-3">
				<h2 className="text-title-xl text-gray-960">Customization</h2>
				<p className="font-semibold text-base mb-6">Tailoring your resume for each application.</p>

				<div className="grid grid-cols-10 gap-8">
					<div className="col-span-10 md:col-span-4">
						<p className="text-md font-semibold text-gray-950">Impact on job search</p>
						<p className="text-md text-gray-800 mb-6">
							A tailored resume demonstrates your genuine interest in the role and increases your chances of passing
							both ATS screenings and human reviews, leading to more interview opportunities.
						</p>
					</div>
					<div className="col-span-10 md:col-span-6">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="col-span-1">
								<LabeledList
									type="do"
									content={[
										"Customize your resume for each job application, highlighting relevant skills and experiences.",
										"Use keywords from the job description to optimize for Applicant Tracking Systems (ATS).",
										"Reorder sections if necessary to prioritize the most relevant information for each role.",
									]}
								/>
							</div>
							<div className="col-span-1">
								<LabeledList
									type="dont"
									content={[
										"Use a generic, one-size-fits-all resume for every job application.",
										"Ignore the specific requirements and qualifications listed in the job description.",
										"Overload your resume with industry jargon or keywords that don't accurately reflect your skills.",
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* cta 3 */}
			<div className="container my-20 md:px-0 px-3">
				<div className="bg-primary-100 rounded-xl px-6 flex md:py-0 py-3 flex-col md:flex-row items-center w-full justify-between">
					<div className="">
						<h3 className="text-title-xl text-gray-950 mb-2">
							Elevate your resume with <br /> professional help
						</h3>
						<p className="text-md text-gray-950">
							Get guidance from a professional resume writer to increase your <br />
							chances of landing a job.
						</p>
					</div>
					<img src="/images/resume-cta-3.png" alt="" />

					<Link
						to="/resume/select-option"
						className="bg-primary-600 text-md text-white font-medium !py-3 px-10 rounded-sm"
					>
						Seek Expert Help
					</Link>
				</div>
			</div>
			{/* length */}
			<div className="container my-20 md:px-0 px-3">
				<h2 className="text-title-xl text-gray-960">Length and Conciseness</h2>
				<p className="font-semibold text-base mb-6">The overall size and content density matters.</p>

				<div className="grid grid-cols-10 gap-8">
					<div className="col-span-10 md:col-span-4">
						<p className="text-md font-semibold text-gray-950">Why employers value this?</p>
						<p className="text-md text-gray-800 mb-6">
							A concise, well-organized resume respects the recruiter&#39;s time and increases the likelihood of your
							key qualifications being noticed and remembered within the 6-second scanning window.
						</p>
					</div>
					<div className="col-span-10 md:col-span-6">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="col-span-1">
								<LabeledList
									type="do"
									content={[
										"Keep your resume concise, typically 1-2 pages depending on your experience level.",
										"Use bullet points and short paragraphs to make information easy to scan.",
										"Focus on the most relevant and recent experiences that showcase your qualifications.",
									]}
								/>
							</div>
							<div className="col-span-1">
								<LabeledList
									type="dont"
									content={[
										"Create an excessively long resume that includes every detail of your work history.",
										"Use long, dense paragraphs that are difficult to read quickly.",
										"Include outdated or irrelevant information that doesn't support your candidacy for the target role.",
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* formatting */}
			<div className="container my-20 md:px-0 px-3">
				<div className="flex flex-col md:flex-row items-center justify-between mb-6">
					<div>
						<h2 className="text-title-xl text-gray-960">Consistency in Formatting and Style</h2>
						<p className="font-semibold text-base mb-6">
							Our templates maintain consistent styling throughout your resume.
						</p>
					</div>
					<Link
						className="text-primary-700 bg-primary-100 font-medium text-md border !border-gray-100 rounded-full px-4 py-3"
						to="/resume/select-option"
					>
						Check Templates &rarr;
					</Link>
				</div>

				<div className="grid grid-cols-10 gap-8">
					<div className="col-span-10 md:col-span-4">
						<p className="text-md font-semibold text-gray-950">Why is it important?</p>
						<p className="text-md text-gray-800 mb-6">
							Consistent formatting creates a polished, professional appearance that enhances readability and reflects
							your attention to detail, a quality valued by employers.
						</p>
					</div>
					<div className="col-span-10 md:col-span-6">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="col-span-1">
								<img src="/images/resume-professional-summary-do.png" className="!mb-5 mx-auto" alt="" />
								<LabeledList
									type="do"
									content={[
										"Maintain consistent font sizes for headings, subheadings, and body text throughout the document.",
										"Use the same date format, bullet style, and indentation throughout your resume.",
										"Ensure consistent spacing between sections and line spacing within sections.",
									]}
								/>
							</div>
							<div className="col-span-1">
								<img src="/images/resume-professional-summary-dont.png" className="!mb-5 mx-auto" alt="" />

								<LabeledList
									type="dont"
									content={[
										"Mix different font styles or sizes randomly throughout the document.",
										"Use inconsistent formatting for similar information (e.g., different date formats for work experiences).",
										"Overuse bold, italics, or underlining, which can make the resume look cluttered.",
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* proofreading */}
			<div className="container my-20 md:px-0 px-3">
				<h2 className="text-title-xl text-gray-960">Proofreading and Editing</h2>
				<p className="font-semibold text-base mb-6">
					Review and refine your resume to eliminate errors and improve clarity.
				</p>

				<div className="grid grid-cols-10 gap-8">
					<div className="col-span-10 md:col-span-4">
						<p className="text-md font-semibold text-gray-950">Why does it matter?</p>
						<p className="text-md text-gray-800 mb-6">
							A meticulously proofread resume demonstrates your attention to detail and communication skills, preventing
							small errors from undermining your professional image.
						</p>
					</div>
					<div className="col-span-10 md:col-span-6">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="col-span-1">
								<LabeledList
									type="do"
									content={[
										"Thoroughly proofread your resume multiple times to catch any spelling, grammar, or punctuation errors.",
										"Ask a trusted friend or professional to review your resume for clarity and errors.",
										"Double-check all dates, job titles, and company names for accuracy.",
									]}
								/>
							</div>
							<div className="col-span-1">
								<LabeledList
									type="dont"
									content={[
										"Rely solely on spell-check, as it may miss context-specific errors or typos that form other words.",
										"Ignore the importance of proper capitalization and punctuation",
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* cta 4 */}
			<div className="container my-20 md:px-0 px-3">
				<div className="bg-primary-100 rounded-xl px-6 py-7 flex flex-col md:flex-row items-center w-full justify-between">
					<div className="flex flex-col md:flex-row items-center gap-4">
						<img src="/images/resume-cta-4.png" alt="" />
						<div className="">
							<h3 className="text-title-xl text-gray-950 mb-2">
								Strengthen your resume with <br />
								trusted feedback
							</h3>
							<p className="text-md text-gray-950">
								Get valuable insights from people you trust to refine your resume using Dproz.
							</p>
						</div>
					</div>
					<Link
						to="/resume/select-experience"
						className="bg-primary-600 text-md text-white font-medium md:my-0 my-3 !py-3 px-10 rounded-sm"
					>
						Build Your Resume
					</Link>
				</div>
			</div>
			{/* naming */}
			<div className="container my-20 md:px-0 px-3">
				<h2 className="text-title-xl text-gray-960">File Format and Naming Considerations</h2>
				<p className="font-semibold text-base mb-6">Ensuring accessibility.</p>
				<div className="grid  grid-cols-1 md:grid-cols-3 gap-8">
					<div className="col-span-1">
						<p className="text-md font-semibold text-gray-950">Best Practices</p>
						<p className="text-md text-gray-800 mb-6">
							{`Save your resume as a PDF to preserve formatting across different devices and systems. Use a clear,
							professional file name that includes your name and 'resume' (e.g., 'John_Doe_Resume.pdf'). Always test the
							file to ensure it opens correctly and all formatting is preserved. Avoid submitting your resume in
							outdated or uncommon file formats, and don't use vague or unprofessional file names.`}
						</p>
					</div>
					<div className="col-span-1">
						<p className="text-md font-semibold text-gray-950">Why does it matter?</p>
						<p className="text-md text-gray-800 mb-6">
							Proper formatting and file handling ensure your resume is easily accessible, maintains its intended
							layout, and presents a professional image even before it&#39;s opened. This attention to detail can make a
							significant difference in how your application is perceived by potential employers.
						</p>
					</div>
					<div className="col-span-1">
						<img src="/images/resume-naming.png" alt="" className="mx-auto" />
					</div>
				</div>
			</div>
			{/* footer */}
			<div className="bg-primary-800">
				<div className="container !py-20">
					<div className="text-center max-w-3xl mx-auto">
						<h1 className="text-primary-200 text-5xl mb-2">Start creating your job-winning resume now</h1>
						<p className="text-primary-200 !mb-5">
							Put all these tips into action and create a powerful resume with Dproz&#39;s easy-to-use platform.
						</p>
						<button
							onClick={() => window.location.replace("/resume/select-experience")}
							className="bg-white text-primary-800 rounded font-semibold !py-4 !px-16 mx-auto"
						>
							Get Started
						</button>
					</div>
				</div>
				<img src="/images/resume-footer.png" className="bottom-0 mx-auto md:max-w-screen-lg" alt="" />
			</div>
		</>
	);
}

export default ResumeTips;
