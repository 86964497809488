import "../css/global.css";
import "../css/home.css";
import "../css/responsive.css";

import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";

function Footer() {
	return (
		<>
			<section className="bg-gray-900 text-white">
				<div className="container section-content">
					<h1 className="text-center">Let’s Connect You to Your Dream Job</h1>
					<p className="text-center mb-4">Build your resume, set your preferences and we’ll handle the rest.</p>
					<div className="flex justify-center mb-5">
						<Link
							to="/resume/select-experience"
							className="btn px-4 py-2 mb-4 rounded bg-primary text-white font-weight-bold hover:text-white"
						>
							Start Now
						</Link>
					</div>
					<hr className="w-full border-light mb-5" />
					<div className="grid grid-cols-6">
						<div>
							<h4 className="text-white">Company</h4>
							<div>
								<Link to="/about-us">About us</Link>
							</div>
						</div>
						<div>
							<h4 className="text-white">Resources</h4>
							<div className="mb-3">
								<Link to="">Blog</Link>
							</div>
							<div className="mb-3">
								<Link to="/faq">FAQs</Link>
							</div>
						</div>
						<div>
							<h4 className="text-white">Legal</h4>
							<div className="mb-3">
								<Link to="/terms-and-conditions">Privacy</Link>
							</div>
							<div className="mb-3">
								<Link to="/privacy-policy">Terms of Service</Link>
							</div>
						</div>
						<div>
							<h4 className="text-white">Contact</h4>
							<div className="mb-3">
								<Link to="">Contact us</Link>
							</div>
						</div>
						<div className="col-span-2">
							<p>@ {dayjs().format("YYYY")} TE-VENTURES INC LTD</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Footer;
