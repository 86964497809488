import React from "react";

export const LocationIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth="1.5"
		stroke="currentColor"
		className="w-6 h-6"
		{...props}
	>
		<path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
		/>
	</svg>
);

export const SearchIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth="1.5"
		stroke="currentColor"
		className="w-6 h-6"
		{...props}
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
		/>
	</svg>
);

export const CalendarIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth="1.5"
		stroke="currentColor"
		className="w-6 h-6"
		{...props}
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
		/>
	</svg>
);

export const BookmarkIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth="1.5"
		stroke="currentColor"
		className="w-6 h-6"
		{...props}
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
		/>
	</svg>
);

export const GlobeIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth="1.5"
		stroke="currentColor"
		className="w-6 h-6"
		{...props}
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64"
		/>
	</svg>
);

export const UsersIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth="1.5"
		stroke="currentColor"
		className="w-6 h-6"
		{...props}
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
		/>
	</svg>
);

export const BuildingIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth="1.5"
		stroke="currentColor"
		className="w-6 h-6"
		{...props}
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
		/>
	</svg>
);

export const MapPinIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth="1.5"
		stroke="currentColor"
		className="w-6 h-6"
		{...props}
	>
		<path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
		/>
	</svg>
);

export const BriefcaseIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth="1.5"
		stroke="currentColor"
		className="w-6 h-6"
		{...props}
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
		/>
	</svg>
);
export const CheckIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width="24"
		height="24"
		color="#000000"
		fill="none"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.75 22.5C5.81294 22.5 1 17.6871 1 11.75C1 5.81294 5.81294 1 11.75 1C17.6871 1 22.5 5.81294 22.5 11.75C22.5 17.6871 17.6871 22.5 11.75 22.5ZM16.5182 9.39018C16.8718 8.9659 16.8145 8.33534 16.3902 7.98177C15.9659 7.62821 15.3353 7.68553 14.9818 8.10981L10.6828 13.2686L8.45711 11.0429C8.06658 10.6524 7.43342 10.6524 7.04289 11.0429C6.65237 11.4334 6.65237 12.0666 7.04289 12.4571L10.0429 15.4571C10.2416 15.6558 10.5146 15.7617 10.7953 15.749C11.076 15.7362 11.3384 15.606 11.5182 15.3902L16.5182 9.39018Z"
			fill="currentColor"
		/>
	</svg>
);

export const Tick = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M4.25 13.5L8.75 18L19.75 6"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const ChevronRightIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M9.00005 6L15 12L9 18"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeMiterlimit="16"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export const Clock = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
			stroke="currentColor"
			strokeWidth="1.5"
		/>
		<path d="M12 8V12L14 14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);
export const CircleIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
	</svg>
);

export const XIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const XCircle = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M15.5 15.5L8.50076 8.5M8.50151 15.5L15.5008 8.5"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const HelpCircle = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<circle
			cx="12"
			cy="12"
			r="10"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
		<path fillRule="evenodd" clipRule="evenodd" d="M12 16.5H12.009H12Z" fill="currentColor" />
		<path d="M12 16.5H12.009" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M10 9.5C10 8.39543 10.8954 7.5 12 7.5C13.1046 7.5 14 8.39543 14 9.5C14 9.89815 13.8837 10.2691 13.6831 10.5808C13.0854 11.5097 12 12.3954 12 13.5"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
);
export const HomeIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.5315 10.5849L20.75 9.95977V18.9993C20.75 20.5181 19.5188 21.7493 18 21.7493H6.00003C4.48124 21.7493 3.25003 20.5181 3.25003 18.9993V9.95977L2.46855 10.5849C2.1451 10.8437 1.67313 10.7913 1.41438 10.4678C1.15562 10.1444 1.20806 9.6724 1.53151 9.41365L10.2821 2.41316C11.2865 1.60968 12.7136 1.60968 13.7179 2.41316L22.4685 9.41365C22.792 9.6724 22.8444 10.1444 22.5857 10.4678C22.3269 10.7913 21.855 10.8437 21.5315 10.5849ZM13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17V18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18V17Z"
			fill="currentColor"
		/>
	</svg>
);

export const DollarSign = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
			stroke="currentColor"
			strokeWidth="1.5"
		/>
		<path
			d="M14.7102 10.0611C14.6111 9.29844 13.7354 8.06622 12.1608 8.06619C10.3312 8.06616 9.56136 9.07946 9.40515 9.58611C9.16145 10.2638 9.21019 11.6571 11.3547 11.809C14.0354 11.999 15.1093 12.3154 14.9727 13.956C14.836 15.5965 13.3417 15.951 12.1608 15.9129C10.9798 15.875 9.04764 15.3325 8.97266 13.8733M11.9734 6.99805V8.06982M11.9734 15.9031V16.998"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
);
export const ChevronDown = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M5.99977 9.00005L11.9998 15L17.9998 9"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeMiterlimit="16"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const LinkedInIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M6 9H2V21H6V9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const TwitterIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M22 4C22 4 21.3 6.1 20 7.4C21.6 17.4 10.6 24.7 2 19C4.2 19.1 6.4 18.4 8 17C3 15.5 0.5 9.6 3 5C5.2 7.6 8.6 9.1 12 9C11.1 4.8 16 2.4 19 5.2C20.1 5.2 22 4 22 4Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export const BellIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M15 19C15 20.6569 13.6373 22 11.9759 22C10.3145 22 9 20.6569 9 19"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M5 12V14L3 16.5V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V16.5L19 14V12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.4387 3.31623L11 5H13L13.5613 3.31623C13.7771 2.6687 13.2951 2 12.6126 2H11.3874C10.7049 2 10.2229 2.6687 10.4387 3.31623Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const Trash = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M19.5 5.5L18.6139 20.121C18.5499 21.1766 17.6751 22 16.6175 22H7.38246C6.32488 22 5.4501 21.1766 5.38612 20.121L4.5 5.5"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3 5.5H8M21 5.5H16M16 5.5L14.7597 2.60608C14.6022 2.2384 14.2406 2 13.8406 2H10.1594C9.75937 2 9.39783 2.2384 9.24025 2.60608L8 5.5M16 5.5H8"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M9.5 16.5L9.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M14.5 16.5L14.5 10.5"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const Sort = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path d="M4 5L20 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M18 12L6 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M8 19L16 19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);

export const Calendar = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M3 9H21M7 3V5M17 3V5M6 13H8M6 17H8M11 13H13M11 17H13M16 13H18M16 17H18M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>
);

export const Upload = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M12 16.5V6.5M12 6.5L8.5 10M12 6.5L15.5 10"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3 15.8C3 14.1198 3 13.2798 3.32698 12.638C3.6146 12.0735 4.07354 11.6146 4.63803 11.327C5.27976 11 6.11984 11 7.8 11H16.2C17.8802 11 18.7202 11 19.362 11.327C19.9265 11.6146 20.3854 12.0735 20.673 12.638C21 13.2798 21 14.1198 21 15.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15.8Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const Bell = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M9 17.5V18.5C9 20.1569 10.3431 21.5 12 21.5C13.6569 21.5 15 20.1569 15 18.5V17.5M5.99999 8.5C5.99999 5.18629 8.68628 2.5 12 2.5C15.3137 2.5 18 5.18629 18 8.5V11.5C18 12.8261 18.6291 13.9271 19.2929 14.7574C19.6343 15.1795 19.8049 15.3905 19.8049 15.5C19.8049 15.6095 19.7839 15.6905 19.7418 15.7889C19.6997 15.8873 19.5906 16.0157 19.3723 16.2724C18.7529 16.9919 17.3483 17.5 12 17.5C6.65165 17.5 5.24714 16.9919 4.62771 16.2724C4.40938 16.0157 4.30022 15.8873 4.25814 15.7889C4.21606 15.6905 4.19507 15.6095 4.19507 15.5C4.19507 15.3905 4.36572 15.1795 4.70703 14.7574C5.37088 13.9271 5.99999 12.8261 5.99999 11.5V8.5Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const Circle = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<circle cx="12" cy="12" r="8" stroke="currentColor" strokeWidth="1.5" />
	</svg>
);

export const FileIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.19144 22.75C4.58211 22.75 3.27678 21.4403 3.27427 19.823L3.25 4.18619C3.24749 2.56532 4.55429 1.25 6.16717 1.25H12.9695C13.2271 1.25 13.4741 1.35268 13.6564 1.53551L20.4645 8.36329C20.6473 8.5466 20.75 8.79546 20.75 9.05498V19.8184C20.75 21.4375 19.4439 22.75 17.8328 22.75H6.19144ZM18.8043 9.46094L12.5673 3.20594V7.96094C12.5673 8.78936 13.2389 9.46094 14.0673 9.46094H18.8043ZM7.25391 18C7.25391 18.4142 7.58969 18.75 8.00391 18.75H16.0039C16.4181 18.75 16.7539 18.4142 16.7539 18C16.7539 17.5858 16.4181 17.25 16.0039 17.25H8.00391C7.58969 17.25 7.25391 17.5858 7.25391 18ZM8.00391 14.75C7.58969 14.75 7.25391 14.4142 7.25391 14C7.25391 13.5858 7.58969 13.25 8.00391 13.25H12.0039C12.4181 13.25 12.7539 13.5858 12.7539 14C12.7539 14.4142 12.4181 14.75 12.0039 14.75H8.00391Z"
			fill="currentColor"
		/>
	</svg>
);

export const DownloadIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
			stroke="currentColor"
			strokeWidth="1.5"
		/>
		<path
			d="M9.5 10.5L12 13L14.5 10.5M12 7V12.3912M8.99023 16H14.9902"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const AlertIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
		<path d="M12 8.5V12.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path fillRule="evenodd" clipRule="evenodd" d="M12 15.5H12.009H12Z" fill="currentColor" />
		<path d="M12 15.5H12.009" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);

export const PreferencesIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.7224 1.25C9.96916 1.25 9.30042 1.732 9.06222 2.4466L8.37472 4.5091L7.31134 5.18166L5.06975 4.62702C4.32099 4.44175 3.53922 4.76857 3.1451 5.43163L1.78091 7.72672C1.35069 8.45051 1.4969 9.37806 2.12889 9.93442L3.6646 11.2863V12.7137L2.12936 14.0656C1.49752 14.622 1.35141 15.5494 1.78157 16.2732L3.14583 18.5684C3.53994 19.2314 4.32171 19.5582 5.07048 19.373L7.33457 18.8128L8.28531 19.3459L8.90518 21.4867C9.12185 22.235 9.80709 22.75 10.5861 22.75H13.4179C14.197 22.75 14.8822 22.235 15.0989 21.4867L15.7188 19.3458L16.6688 18.8128L18.9328 19.373C19.6816 19.5582 20.4634 19.2314 20.8575 18.5684L22.2475 16.2298C22.6571 15.5406 22.5461 14.6612 21.9779 14.0955L20.3927 12.5172L20.3931 11.4831L21.9786 9.90447C22.5468 9.33878 22.6579 8.45936 22.2482 7.77018L20.8582 5.43163C20.4641 4.76857 19.6823 4.44175 18.9336 4.62702L16.692 5.18166L15.6286 4.5091L14.9411 2.4466C14.7029 1.732 14.0341 1.25 13.2809 1.25L10.7224 1.25ZM12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z"
			fill="currentColor"
		/>
	</svg>
);

export const JobsIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.13743 3.71716C8.27819 2.73186 9.12203 2 10.1173 2H14.3827C15.378 2 16.2219 2.73186 16.3626 3.71716L16.74 6.35858C16.8181 6.90531 16.4382 7.41184 15.8915 7.48995C15.3447 7.56805 14.8382 7.18816 14.7601 6.64142L14.3827 4H10.1173L9.73999 6.64142C9.66188 7.18816 9.15535 7.56805 8.60861 7.48995C8.06188 7.41184 7.68198 6.90531 7.76009 6.35858L8.13743 3.71716Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.75003 5.75C2.78353 5.75 2.00003 6.5335 2.00003 7.5V10.4567C2.00003 10.6758 2.04107 10.9068 2.137 11.1271C3.80107 14.9479 8.45353 16.386 12.6604 16.2398C14.8004 16.1654 16.9291 15.6848 18.6707 14.8305C20.4029 13.9808 21.834 12.7175 22.4131 11.035C22.4764 10.851 22.5 10.6679 22.5 10.4996V7.5C22.5 6.5335 21.7165 5.75 20.75 5.75H3.75003ZM12.25 10.5C11.6977 10.5 11.25 10.9477 11.25 11.5C11.25 12.0523 11.6977 12.5 12.25 12.5H12.259C12.8113 12.5 13.259 12.0523 13.259 11.5C13.259 10.9477 12.8113 10.5 12.259 10.5H12.25Z"
			fill="currentColor"
		/>
		<path
			d="M3.00003 14.7803V19.9995C3.00003 21.5183 4.23125 22.7495 5.75003 22.7495H18.75C20.2688 22.7495 21.5 21.5183 21.5 19.9995V14.7884C20.8339 15.3433 20.092 15.8036 19.3313 16.1767C17.3695 17.1391 15.0272 17.658 12.7125 17.7384C9.46184 17.8514 5.61441 17.0771 3.00003 14.7803Z"
			fill="currentColor"
		/>
	</svg>
);

export const DocumentIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.50017 1.25C3.9814 1.25 2.75018 2.4812 2.75017 3.99998L2.75003 20C2.75002 21.5188 3.98124 22.75 5.50003 22.75H12.9549C12.3611 22.0866 12 21.2104 12 20.25V16.75C12 15.3611 12.6299 14.0773 13.4977 13.1641C14.3804 12.2351 15.6917 11.5 17.25 11.5C18.8084 11.5 20.1196 12.2351 21.0024 13.1641C21.0873 13.2534 21.17 13.3464 21.25 13.4426V4C21.25 2.48122 20.0188 1.25 18.5 1.25H5.50017ZM6.75003 6C6.75003 5.58579 7.08582 5.25 7.50003 5.25H16.5C16.9142 5.25 17.25 5.58579 17.25 6C17.25 6.41421 16.9142 6.75 16.5 6.75H7.50003C7.08582 6.75 6.75003 6.41421 6.75003 6ZM7.50003 9.25C7.08582 9.25 6.75003 9.58579 6.75003 10C6.75003 10.4142 7.08582 10.75 7.50003 10.75H13.5C13.9142 10.75 14.25 10.4142 14.25 10C14.25 9.58579 13.9142 9.25 13.5 9.25H7.50003Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.4038 14.0251C15.098 13.2945 16.0976 12.75 17.25 12.75C18.4024 12.75 19.402 13.2945 20.0962 14.0251C20.7838 14.7486 21.25 15.7359 21.25 16.75V19.75C21.25 20.3023 20.8023 20.75 20.25 20.75C19.6977 20.75 19.25 20.3023 19.25 19.75L19.25 16.75C19.25 16.3346 19.0447 15.8219 18.6464 15.4028C18.2549 14.9908 17.7544 14.75 17.25 14.75C16.7456 14.75 16.2451 14.9908 15.8536 15.4028C15.4553 15.8219 15.25 16.3346 15.25 16.75V20.25C15.25 20.5261 15.4739 20.75 15.75 20.75C16.0261 20.75 16.25 20.5261 16.25 20.25V16.75C16.25 16.1977 16.6977 15.75 17.25 15.75C17.8023 15.75 18.25 16.1977 18.25 16.75V20.25C18.25 21.6307 17.1307 22.75 15.75 22.75C14.3693 22.75 13.25 21.6307 13.25 20.25L13.25 16.75C13.25 15.7359 13.7162 14.7486 14.4038 14.0251Z"
			fill="currentColor"
		/>
	</svg>
);

export const ResumeIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.1716 1.25C14.1978 1.25 14.2239 1.25037 14.25 1.25112V5.99958C14.25 6.96607 15.0335 7.74957 16 7.74958L20.7489 7.74964C20.7496 7.77586 20.75 7.80212 20.75 7.82843V20C20.75 21.5188 19.5188 22.75 18 22.75H6C4.48122 22.75 3.25 21.5188 3.25 20V4C3.25 2.48122 4.48122 1.25 6 1.25H14.1716ZM19.9445 5.88388C20.0581 5.99744 20.1607 6.1199 20.2517 6.24963L16 6.24958C15.8619 6.24958 15.75 6.13765 15.75 5.99958V1.74808C15.8799 1.83911 16.0025 1.94179 16.1161 2.05546L19.9445 5.88388ZM8 10.25C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H16C16.4142 11.75 16.75 11.4142 16.75 11C16.75 10.5858 16.4142 10.25 16 10.25H8ZM8 13.25C7.58579 13.25 7.25 13.5858 7.25 14C7.25 14.4142 7.58579 14.75 8 14.75H16C16.4142 14.75 16.75 14.4142 16.75 14C16.75 13.5858 16.4142 13.25 16 13.25H8ZM8 16.25C7.58579 16.25 7.25 16.5858 7.25 17C7.25 17.4142 7.58579 17.75 8 17.75H12.1708C12.585 17.75 12.9208 17.4142 12.9208 17C12.9208 16.5858 12.585 16.25 12.1708 16.25H8Z"
			fill="currentColor"
		/>
	</svg>
);

export const FiltersIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M19.5 2.5C20.6046 2.5 21.5 3.39543 21.5 4.5V19.5C21.5 20.6046 20.6046 21.5 19.5 21.5H4.5C3.39543 21.5 2.5 20.6046 2.5 19.5L2.5 4.5C2.5 3.39543 3.39543 2.5 4.5 2.5L19.5 2.5Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M16 14C16.5523 14 17 14.4477 17 15L17 16C17 16.5523 16.5523 17 16 17L15 17C14.4477 17 14 16.5523 14 16L14 15C14 14.4477 14.4477 14 15 14L16 14Z"
			stroke="currentColor"
			strokeWidth="1.5"
		/>
		<path
			d="M9 7C9.55228 7 10 7.44772 10 8L10 9C10 9.55228 9.55228 10 9 10L8 10C7.44772 10 7 9.55228 7 9L7 8C7 7.44772 7.44772 7 8 7L9 7Z"
			stroke="currentColor"
			strokeWidth="1.5"
		/>
		<path d="M7.5 15.5H14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M10.5 8.5H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);
