import React from "react";

import { cn } from "../../handlers/cn.utils";

function Tabs({ setCurrentView, tabs, currentView }) {
	return (
		<ul className="flex w-full justify-between">
			{tabs.map((tab) => (
				<li
					key={tab.key}
					onClick={() => setCurrentView(tab.key)}
					className={cn("cursor-pointer py-2 px-4 flex-1 text-center text-lg", {
						"font-bold text-primary-700 border-b-2 border-b-primary-700": tab.key === currentView,
						"text-gray-500": tab.key !== currentView,
					})}
				>
					{tab.name}
				</li>
			))}
		</ul>
	);
}

export default Tabs;
