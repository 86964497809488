import { useQuery } from "@tanstack/react-query";

import { API } from "../apiService";

export function useCompanies() {
	return useQuery({
		queryKey: ["companies"],
		queryFn: async () => await API.get("companies"),
		staleTime: Infinity,
	});
}

export function useCompany(id) {
	return useQuery({
		queryKey: ["companies", id],
		queryFn: async () => await API.get(`companies?companyId=${id}`),
		staleTime: Infinity,
	});
}

export function useJobCategories() {
	return useQuery({
		queryKey: ["jobs", "categories"],
		queryFn: async () => await API.get("jobs/categories"),
		staleTime: Infinity,
	});
}

export function useLatestJobs() {
	return useQuery({
		queryKey: ["jobs", "latest"],
		queryFn: async () => await API.get("jobs/latest"),
		staleTime: 60 * 1000,
	});
}

export function useUpcomingJobs() {
	return useQuery({
		queryKey: ["jobs", "upcoming"],
		queryFn: async () => await API.get("jobs/upcoming"),
		staleTime: 60 * 1000,
	});
}

export function useFeaturedEmployers() {
	return useQuery({
		queryKey: ["employers", "featured"],
		queryFn: async () => await API.get("jobs/employers/featured"),
		staleTime: 60 * 1000,
	});
}
