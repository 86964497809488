import React from "react";
import { useNavigate } from "react-router-dom";

function SimilarCompanies({ company }) {
	const { companyDetails } = company;

	const navigate = useNavigate();

	return (
		<div
			className="bg-white rounded-lg p-6 flex flex-col h-full"
			style={{ boxShadow: "0 0 0 1px rgba(0,0,0,.05), 0 2px 2px rgba(0,0,0,.04)" }}
		>
			<div className="flex items-center mb-3">
				<div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0 border border-gray-100">
					<img
						alt="AfriTech Solutions logo"
						loading="lazy"
						width="64"
						height="64"
						decoding="async"
						data-nimg="1"
						className="w-full h-full object-cover"
						src={companyDetails.logo_url}
					/>
				</div>
				<h3 className="ml-4 text-xl font-semibold text-gray-900">{companyDetails.company_name}</h3>
			</div>
			<p className="text-gray-700 mb-3">{companyDetails.company_industry}</p>
			<div className="flex flex-wrap gap-2 mb-3">
				<span className="inline-flex items-center h-6 px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700">
					{companyDetails.company_industry}
				</span>
				<span className="inline-flex items-center h-6 px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700">
					{companyDetails.company_size_approx} employees
				</span>
				<span className="inline-flex items-center h-6 px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700">
					{companyDetails.headquarters}
				</span>
			</div>
			<div className="mt-auto pt-4">
				<div className="flex space-x-2">
					<span
						className="btn bg-gray-100 text-black w-full"
						onClick={() =>
							navigate(`/companies/${company.company}`, {
								state: { company },
							})
						}
					>
						View Profile
					</span>
					<button className="btn text-black border-gray-200 w-full">Follow</button>
				</div>
			</div>
		</div>
	);
}

export default SimilarCompanies;
